import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { constant } from '../constant/constant';
import { TokenStorageService } from '../services/token-storage.service';
import { UtilityService } from '../services/utility.service';
import { ResponseStatus } from '../models/ApiResponse';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login-without-captcha',
  templateUrl: './login-without-captcha.component.html',
  styleUrls: ['./login-without-captcha.component.css']
})

export class LoginWithoutCaptchaComponent implements OnInit {



  user: any = {};
  loggedIn = false;
  token: string | undefined;
  AppIDList: any = [];
  userRequest: any = {};
  hide: boolean = true;
  // authData : Auth ={
  //   user: undefined,
  //   stationId: '',
  //   appId: []
  // };
  myFunction() {
    this.hide = !this.hide;
  }

  constructor(private http: HttpClient, private router: Router, private tokenStorageService: TokenStorageService,
    private utilityService: UtilityService, private userService: UserService) { this.token = undefined; }

  ngOnInit(): void {
  }

  loginUser() {
    this.AppIDList.length = 0;
    this.AppIDList.push(constant.STORECONFIGURATOR);
    this.user.appId = this.AppIDList;

    this.userRequest = {
      user: this.user,
      appId: this.AppIDList
    }

    this.userService.login(this.userRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedResponse = JSON.parse(response.data);

          this.tokenStorageService.saveToken(receivedResponse.token);
          this.tokenStorageService.saveRefreshToken(receivedResponse.user.refreshToken);
          this.tokenStorageService.saveUser(receivedResponse.user);

          let userDetails = receivedResponse.user;
          if (userDetails.role == 'MMSAdministrator') {
            sessionStorage.setItem('roleType', 'MMSAdministrator');
            this.router.navigateByUrl('/organization');
          } else {
            sessionStorage.setItem('roleType', 'Administrator');
            this.router.navigateByUrl('/adminPage');
          }

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  resetPassword() {

  }
}
