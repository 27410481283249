<div class="dialog-headline">
    <mat-label style="float:left;">Update Pharmacy Password</mat-label>
    <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography" style="padding-top: 20px;">

  <div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Password" name="password" #password="ngModel" id="password"
            [(ngModel)]="Store.password" required [type]="hideNewPwd ? 'password' : 'text'" autocomplete="new-password"
            (ngModelChange)="validateConfirmPassword()">

            <mat-icon class="pointer" matSuffix (click)="hideNewPwd = !hideNewPwd">{{hideNewPwd ?
              'visibility_off' :
              'visibility'}}
          </mat-icon>
          
        </mat-form-field>

      
        <mat-password-strength (onStrengthChanged)="onPasswordStrengthChanged($event);" [externalError]="true"
          [min]="8" [max]="10" #passwordComponent [password]="Store.password">
        </mat-password-strength>
  
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
            [hideToggle]="showError">
            <mat-expansion-panel-header>
              <mat-panel-description>
                <mat-icon *ngIf="!panelOpenState">arrow_forward</mat-icon>
                <mat-icon *ngIf="panelOpenState">arrow_downward</mat-icon>
                Expand to see errors
              </mat-panel-description>
            </mat-expansion-panel-header>
            <mat-password-strength-info [passwordComponent]="passwordComponent">
            </mat-password-strength-info>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
  
      <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
  
      <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
        <mat-form-field>
          <input matInput placeholder="Confirm Password" name="confirmPassword" id="confirmPassword"
            [(ngModel)]="Store.confirmPassword" required [type]="hideConfirmPwd ? 'password' : 'text'" 
            autocomplete="new-password" (ngModelChange)="validateConfirmPassword()">

            <mat-icon class="pointer" matSuffix (click)="hideConfirmPwd = !hideConfirmPwd">{{hideConfirmPwd ?
              'visibility_off' :
              'visibility'}}
          </mat-icon>
  
          <mat-error *ngIf="isConfirmPasswordValid">
            Confirm password does not match with password.
          </mat-error>
        </mat-form-field>
      </div> 
    </div>
  </div>

  <mat-dialog-content class="mat-typography" style="padding-top: 20px;">
    <div fxLayout="row" fxLayoutAlign="end center">
      <button mat-button (click)="closeModal()">Cancel</button>
      <button mat-raised-button color="primary" (click)="resetPassword(Store)"
      [disabled]="isResetButtonDisabled">Reset</button>
    </div>
  </mat-dialog-content>

</mat-dialog-content>