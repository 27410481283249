import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EDIStoreAdminService } from 'src/app/services/edi_storeAdmin.service';
import { MPOSStoreAdminService } from 'src/app/services/mpos_storeAdmin.service';
import { StoreAdminService } from 'src/app/services/storeAdmin.service';
import { UtilityService } from 'src/app/services/utility.service';
import { constant } from 'src/app/constant/constant';
import { StoreService } from 'src/app/services/store.service';
import { forkJoin } from 'rxjs';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { StoreAdminModalComponent } from '../store-admin-modal/store-admin-modal.component';

@Component({
  selector: 'app-store-admin-password-reset-modal',
  templateUrl: './store-admin-password-reset-modal.component.html',
  styleUrls: ['./store-admin-password-reset-modal.component.css']
})
export class StoreAdminPasswordResetModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public receivedData: any,private storeAdminService: StoreAdminService,
    private EDIstoreAdminService: EDIStoreAdminService , private MPOSstoreAdminService: MPOSStoreAdminService,
    private storeService: StoreService, private utilityService: UtilityService,private dialogRef: MatDialogRef<StoreAdminPasswordResetModalComponent>
  ) {}

  Store: any = {};
  showError: boolean = false;
  panelOpenState: boolean = false;
  isPasswordStrong: boolean = false;
  isConfirmPasswordValid: boolean = true; 
  newPassword: string = '';
  message: any = "";
  confirmPassword: string = '';
  hideNewPwd: boolean = true;
  hideConfirmPwd: boolean = true;

  ngOnInit(): void {
    if (this.receivedData.userData != undefined) {
      this.Store = this.receivedData.userData;
      this.Store.password = "";
      this.Store.confirmPassword = "";
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  resetPassword(user: any){
    const resetPasswordRequest = {
      newPassword: user.confirmPassword,
      userName: user.userName
  };
    this.MPOSstoreAdminService.Reset(resetPasswordRequest).subscribe(response => {
      if (response.result == constant.SUCCESS) {
        this.message = "Pharmacy admin updated in Store";
                console.log('mpos repsonse');
                  this.message = this.message + "\n" + "Pharmacy admin updated in ePrimePOS";
                  this.utilityService.showAlert(constant.SUCCESS, "Updated", this.message).subscribe(result => {

                    //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                    this.dialogRef.close(true);
                  });    

      } else {
        this.message = this.message + "\n" + "Error in updating Pharmacy admin in ePrimePOS";
        this.utilityService.showAlert(constant.FAILURE, "Failed", this.message);
      }
    }, error => {
      this.utilityService.showAlert(constant.FAILURE, "Failed", "Error in reseting Pharmacy admin password");
    })
  }

  onPasswordStrengthChanged(event: any) {
    if (event == 100) {
      this.isPasswordStrong = true;
    } else {
      this.isPasswordStrong = false;
    }
  }

  validateConfirmPassword() {
    this.isConfirmPasswordValid = this.Store.password === this.Store.confirmPassword;
  }

  get isResetButtonDisabled(): boolean {
    return this.Store.password === '' || this.Store.confirmPassword !== this.Store.password;
  }
  
}
