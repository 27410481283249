


<div class="main-container cust--login">

  <div fxLayout="row wrap" style="padding-top: 2%;">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
          
        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100">
          <!-- <img width="210px" height="40px" src="assets/images/PrimeRx_W.png"> -->
        </div>
        <div fxFlex.gt-lg="40" fxFlex.gt-md="40" fxFlex.gt-xs="100" fxFlex="100">
           

        </div>
    </div>
</div>

  <div fxLayout="column" fxLayoutAlign="space-evenly center">
    <!-- COLUMN 1 -->
    <div>
      <br>
      <br>
      <br>
      <br>
      <br>
    </div>

    <!-- COLUMN 2 -->
    <div>

      <mat-card>
        
        <hr>
        <mat-card-content fxLayout="column">
          <form #loginUserForm="ngForm" autocomplete="off">
            <div class="form-group row">
              <div class="col-md-10">
                <mat-form-field>
                  <input matInput placeholder="User Name" name="UserName" #UserName="ngModel" id="UserName"
                    [(ngModel)]="user.userName" class="form-group" required>

                  <mat-error *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)" class="invalid-feedback">
                    Please enter user name
                  </mat-error>
                  <div *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)" class="invalid-feedback"
                    id="UserNameError">
                    Please enter user name
                  </div>
                </mat-form-field>

              </div>
              <div class="col-md-2"></div>
            </div>

            <div class="form-group row">
              <div class="col-md-10">

                <mat-form-field>
                  <input matInput placeholder="Password" name="password" #password="ngModel" id="password"
                    [(ngModel)]="user.password" required [type]="hide ? 'password' : 'text'">

                  <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' :
                    'visibility'}}
                  </mat-icon>

                  <mat-error *ngIf="password.invalid && (password.dirty || password.touched)" class="invalid-feedback">
                    Please enter password
                  </mat-error>
                  <div *ngIf="UserName.invalid && (UserName.dirty || UserName.touched)" class="invalid-feedback"
                    id="passwordError">
                    Please enter password
                  </div>
                </mat-form-field>

              </div>

            </div>
            <mat-label style=" float: right; cursor:pointer" (click)="resetPassword()">Forgot
              Password ??? </mat-label>

          </form>
          <br>
          <button mat-raised-button style="width: 100%;" [disabled]="loginUserForm.invalid" (click)="loginUser();"
            color="accent">Login</button>

        </mat-card-content>

      </mat-card>

    </div>

    <div>
      <br>
      <br>
    </div>

    <div>
    </div>

    <div>
      <h4 style="color: black;">© 2025 Micro Merchant Systems, Inc.
        <br> ACv5.1 All rights reserved.</h4>
      
    </div>

  </div>

</div>