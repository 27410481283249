import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';
import { Store } from 'src/app/models/Store';
import { User } from 'src/app/models/User';
import { SessionService } from 'src/app/services/session.service';
import { StoreAdminService } from 'src/app/services/storeAdmin.service';
import { UtilityService } from 'src/app/services/utility.service';
import { StoreAdminModalComponent } from '../store-admin-modal/store-admin-modal.component';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { StoreAdminPasswordResetModalComponent } from '../store-admin-password-reset-modal/store-admin-password-reset-modal.component';

class pagination {
  pageNumber!: number;
  pageSize!: number;
}


@Component({
  selector: 'app-store-admin',
  templateUrl: './store-admin.component.html',
  styleUrls: ['./store-admin.component.css']
})
export class StoreAdminComponent implements OnInit {

  dtOptions: DataTables.Settings = {};
  user: any = {};
  currentPage = 0;
  pageSize = 10;
  totalRows = 0;

  pageData!: pagination;


  displayedColumns = ['userName', 'lName', 'storeName', 'noOfAttempt', 'isLocked', 'isActive', 'Action'];
  pageSizeOptions = [10, 50, 100]
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<Store>;
  selection = new SelectionModel<Store>(true, []);
  storeAdminArray: any = [];


  constructor(private dialogService: MatDialog,
    private storeAdminService: StoreAdminService, private utilityService: UtilityService) { }

  ngOnInit(): void {

    this.getStoreAdminsOnLoad();

  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.getStoreAdminsOnLoad();
    });
  }

  getStoreAdminsOnLoad() {
    this.pageData = {
      pageNumber: this.currentPage + 1,
      pageSize: this.pageSize
    };

    this.storeAdminService.GetStoreAdminOnLoadPagination(this.pageData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          let responseData = JSON.parse(response.data);
          this.storeAdminArray = [];
          this.storeAdminArray = responseData.records;

          if (this.storeAdminArray.length > 0) {
            this.dataSource = new MatTableDataSource<Store>(this.storeAdminArray);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            setTimeout(() => {

              this.paginator!.pageIndex = this.currentPage;
              this.paginator!.length = responseData.totalRecords;


            });
          } else {
            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'No Data Found')

          }

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });


  }
  applyFilter(filterValue: any) {
    filterValue = filterValue.value.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  SearchUser(SearchUser: any) {
    if (SearchUser.userName == undefined || SearchUser.userName == '' || SearchUser.userName.trim() == '') {

      this.getStoreAdminsOnLoad();
      window.location.reload();

    } else {

      this.storeAdminService.SearchStoreAdmin(SearchUser).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {

            let responseData = JSON.parse(response.data);
            this.storeAdminArray = [];
            this.storeAdminArray = responseData;

            if (this.storeAdminArray.length > 0) {
              this.dataSource = new MatTableDataSource<Store>(this.storeAdminArray);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator!;
              this.dataSource.sort = this.sort!;
              setTimeout(() => {

                this.paginator!.pageIndex = this.currentPage;
                this.paginator!.length = responseData.totalRecords;

              });
            } else {

              this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'No Data Found')

            }

          } else if ((response.result == ResponseStatus.ERROR)) {

            this.utilityService.showAlert(constant.KEY_WARNING, constant.KEY_WARNING, "No Data Found")
          }
          else if ((response.result == ResponseStatus.FAILURE)) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
          }
        },
        error: (errorResponse) => {
          console.log(errorResponse);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });


    }
  }

  resetPassword(user: any){  
    
    let passData = {
      promptTitle: "Are you sure you want to reset the password for this user " + user.userName,
      promptMessage: "",
    };
    this.utilityService.openPrompt(passData.promptTitle, passData.promptMessage).subscribe(result => {
      if (result == true) {
        user.pass
       this.dialogService.open(StoreAdminPasswordResetModalComponent, {
      width: '50%',
      disableClose: true,

      data: {
        userData: user,
        headerTitle: "Update Pharmacy Password"
      }
    });
      }
    })
  }

  OpenStoreAdminUpdateModal(passStore: any) {
    this.dialogService.open(StoreAdminModalComponent, {
      width: '50%',
      disableClose: true,

      data: {
        userData: passStore,
        headerTitle: "Update Pharmacy Admin"
      }
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    this.getStoreAdminsOnLoad();

  }


  getStoreAdmins() {
    this.pageData = {
      pageNumber: this.currentPage + 1,
      pageSize: this.pageSize
    };

    this.storeAdminService.GetStoreAdminOnLoadPagination(this.pageData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          const responseData = JSON.parse(response.data);
          this.storeAdminArray = responseData.records;
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }
}
