import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FileParseError } from 'src/app/models/FileParseError';
import { FileParseErrorRequest } from 'src/app/models/RequestModel/FileParseErrorRequest';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { FileParsingErrorService } from 'src/app/services/FileParseError/file-parsing-error.service';
import { EdiUserService } from 'src/app/services/edi_user.service';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { Vendor } from 'src/app/models/Vendor';
import { Store } from 'src/app/models/Store';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { ediconstant } from 'src/app/constant/ediconstant';
import { PharmacyCreateRequest } from 'src/app/models/Pharmacy/PrimeRxCloudPharmacyCreateRequest';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';


@Component({
  selector: 'app-file-parsing-error',
  templateUrl: './file-parsing-error.component.html',
  styleUrls: ['./file-parsing-error.component.css']
})
export class FileParsingErrorComponent implements OnInit {


  //================MATERIAL TABLE===============
  //material table code for displaying the header
  displayedColumns = ['NPINo', 'vendorCode', 'contractName', 'fileName', 'fileType', 'errorDate', 'errorMessages'];
  pageSizeOptions: number[] = [5, 10, 15];
  currentPage = 0;
  pageSize = 5;
  totalRows = 0;
  searchKey = "";
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;
  //  sort: MatSort | undefined;
  dataSource: MatTableDataSource<FileParseError> = new MatTableDataSource();

  //=========variable Declaration============
  fileParsingError: any = {};
  receivedFileResponse: any = {};

  resultData: any;
  fileParseErrorRequest: FileParseErrorRequest = new FileParseErrorRequest;
  userAccessData!: EdiUserAccess;
  currentDate!: any;


  vendor: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    vendorAccount:'',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810: '',
    processSwitch832: '',
    processSwitch835: '',
    processSwitch855: '',
    updateAWPThroughEDI: '0',
    updateCostThroughEDI: '0',
    firstTimeInventory: '0'


  };

  storeNpiList: Store[] = [];

  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;
  selectedNPINo: string = "";

  existingMappedVendor: Vendor[] = [];
  vendorMasterArray: Vendor[] = [];
  filteredVendorOptions: Observable<Vendor[]> | undefined;
  vendorNameControl = new FormControl('');
  storeArray!: Store[];

  constructor(private fileParseErrorService: FileParsingErrorService, private utilityService: EdiUtilityService, private tokenStorageService: TokenStorageService, private ediStoreService: EDIStoreService, private vendorService: VendorService) {
    const user = this.tokenStorageService.getUser();

    if (!this.userAccessData) {
      this.userAccessData = {} as any;
    }

    this.userAccessData.NPINo = user.NPINo;
    this.userAccessData.pseudoName = user.pseudoName;
    this.fileParsingError.NPINo = this.userAccessData.NPINo;
    this.fileParsingError.pseudoName = this.userAccessData.pseudoName;

  }



  ngOnInit(): void {
    this.currentDate = new Date();
    var previousMonth = new Date(this.currentDate);
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    this.fileParsingError.fromDate = previousMonth;
    this.fileParsingError.toDate = new Date();
    this.fileParsingError.fileType = 'All';
    this.loadStoreNpi();
    //FETCHING THE FILE PARSING ERROR DATA ON PAGE VIEW INIT
    this.getFileParseErrorData();

    if (this.userAccessData.NPINo != "" && this.userAccessData.NPINo == "0000000000") {
      //this.isMMSAdmin = true;
      this.loadAllNPINo();
    }
    this.loadMasterVendors();
  }

  loadAllNPINo() {

    this.ediStoreService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.storeArray = receivedVendorResponse;
          this.setValueToNPIDropdown();

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  setValueToNPIDropdown() {

    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }

  loadMasterVendors() {

    let vendorData = {};
    this.vendorService.getMasterVendor(vendorData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.vendorMasterArray = receivedVendorResponse;
          this.vendorMasterArray = this.vendorMasterArray.filter(vendor => vendor.isActive);
          this.getUnMappedVendors(this.userAccessData.NPINo);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo")
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  onFromDateChange() {

    if ((Date.parse(this.fileParsingError.fromDate) > Date.parse(this.fileParsingError.toDate)) && this.fileParsingError.toDate) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than To date").subscribe(result => {
        this.fileParsingError.fromDate = "";
      });

    } else if (Date.parse(this.fileParsingError.fromDate) > Date.parse(this.currentDate)) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select From date lower than current date").subscribe(result => {
        this.fileParsingError.fromDate = "";
      });
    }
  }

  onToDateChange() {


    if ((Date.parse(this.fileParsingError.toDate) < Date.parse(this.fileParsingError.fromDate)) && this.fileParsingError.fromDate) {
      this.utilityService.showAlert("WARNING", "Warning", "To date should be greater than From date").subscribe(result => {
        this.fileParsingError.toDate = "";
      });

    } else if (Date.parse(this.fileParsingError.toDate) > Date.parse(this.currentDate)) {
      this.utilityService.showAlert("WARNING", "Warning", "Please select To date lower than current date").subscribe(result => {
        this.fileParsingError.toDate = "";
      });
    }
  }

  onSearchClear() {
    this.searchKey = "";
    this.applyFilter();
  }


  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();

    setTimeout(() => {

      this.paginator!.pageIndex = this.currentPage;
      this.paginator!.length = this.resultData.totalRecords;

    });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    this.getFileParseErrorData();
  }

  // ================GET THE FILE ERROR DATA BASED ON THE PAGINATION=================
  getFileParseErrorData() {

    // setting request object
    this.fileParseErrorRequest.NPINo = this.vendor.NPINo //this.userAccessData.NPINo;
    if (this.fileParseErrorRequest.NPINo == 'All' || this.fileParseErrorRequest.NPINo == "") this.fileParseErrorRequest.NPINo = '0000000000';
    if (this.fileParsingError.fileType == 'All') this.fileParsingError.fileType = '';
    this.fileParseErrorRequest.fromDate = this.fileParsingError.fromDate;
    this.fileParseErrorRequest.toDate = this.fileParsingError.toDate;

    this.fileParseErrorRequest.startDate = new Date(moment(this.fileParsingError.fromDate).format('YYYY-MM-DD')).toISOString();
    this.fileParseErrorRequest.endDate = new Date(moment(this.fileParsingError.toDate).format('YYYY-MM-DD 23:59:59')).toISOString();

    this.fileParseErrorRequest.fileType = this.fileParsingError.fileType;
    this.fileParseErrorRequest.pagination.pageNumber = this.currentPage + 1;
    this.fileParseErrorRequest.pagination.pageSize = this.pageSize;


    this.fileParseErrorService.getFileParseErrorData(this.fileParseErrorRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.receivedFileResponse = JSON.parse(response.data);

          this.dataSource = new MatTableDataSource<FileParseError>(this.receivedFileResponse.records);

          this.dataSource.paginator = this.paginator!;
          this.dataSource.sort = this.sort!;
          setTimeout(() => {

            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = this.receivedFileResponse.totalRecords;

          });

          if (this.receivedFileResponse.records.length < 1) {
            this.utilityService.showAlert(
              constant.KEY_WARNING,
              constant.ALERT_WARNING_TITLE,
              "No data found"
            );
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching the file parse error data")
        }
        if (this.fileParsingError.fileType == '') this.fileParsingError.fileType = 'All';
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  toggleCommentExpansion(element: any): void {
    element.isExpanded = !element.isExpanded;
  }

  loadStoreNpi() {
    this.ediStoreService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.storeNpiList = receivedVendorResponse;

          const pharmacyCreateRequest: PharmacyCreateRequest = {
            adminFirstName: '',
            adminLastName: '',
            pharmacyEmail: '',
            pharmacyPhoneNumber: '',
            contactPerson: '',
            pharmacyFaxNumber: '',
            rxGenSys: 0, // Assuming rxGenSys is a number property, initialize with appropriate default value
            pharmacyAddressLine1: '',
            pharmacyAddressLine2: '', // If you want to set it to null, remove this line
            masterDbName: '',
            pharmacyNpiNumber: '',
            pharmacyZipCode: '',
            pharmacyNABPNum: 0, // Assuming pharmacyNABPNum is a number property, initialize with appropriate default value
            PharmacyStateCode: "", // Assuming PharmacyStateCode is a number property, initialize with appropriate default value
            pharmacyCityId: 0, // Assuming pharmacyCityId is a number property, initialize with appropriate default value
            pharmacyRegistrationNum: '',
            pharmacyDEARegistration: '',
            pharmacyName: '',
            organizationId: 0,
            adminUserName: '',
            adminPassword: '',
            isPrimeRxCloudEmptyPharmacy:false
          };


          const storeAll: Store = {
            mId: '',
            NPINo: 'All',
            pseudoName: '',
            storeName: '',
            address1: '',
            address2: '',
            city: '',
            zipCode: '',
            state: '',
            owner: '',
            contactNo: '',
            contactName: '',
            website: '',
            fax: '',
            selectedPayType: '',
            isActive: false,
            terminal: '',
            isEdiv2: false,
            isMpos: false,
            isStoreAdminCreated: false,
            createdBy: '',
            updatedBy: '',
            createdDate: '',
            updatedDate: '',
            email: '',
            isPrimeRxCloud: false,
            primeRxCloudData: pharmacyCreateRequest,
            isInfoLog: false, // #3787
            allowDefaultItems: false //#3788
          }

          this.storeNpiList.unshift(storeAll);
          this.vendor.NPINo = 'All';

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error ehile fetching store NPI list");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  // setNPINoToModel(event: any) {
  //   this.vendor.NPINo = event.option.value.NPINo;

  //   this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

  //   //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
  //   this.getUnMappedVendors(this.vendor.NPINo);

  // }

  setNPINoToModel(event: MatAutocompleteSelectedEvent) {
    if (event && event.option && event.option.value) {
      this.vendor.NPINo = event.option.value.NPINo;
      this.selectedNPINo = event.option.value.NPINo; // Adding the value of selected npi to the field
      this.getUnMappedVendors(this.vendor.NPINo);
    } else {
      // Handle the scenario where the user clears the input field
      this.vendor.NPINo = ''; // Clear the value of this.vendor.NPINo
      this.selectedNPINo = ''; // Optionally clear other related properties
    }
  }

  onInput(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    if (!value.trim()) {
      // Clear the value of this.vendor.NPINo if the input field is empty
      this.vendor.NPINo = '';
      this.selectedNPINo = ''; // Optionally clear other related properties
    }
  }




  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe({
			next: (response) => {
			  if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.existingMappedVendor = receivedVendorResponse;

          //now getting the diffrence between the master vendor and mapped  vendor
          this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
            !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

          //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
          this.setValueToVendorDropdown();
			  } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

				this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching mapped vendors data");
			  }
			},
			error: (errorResponse) => {
			  console.log(errorResponse);
			  this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
			}
		  });
  }

  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
}
