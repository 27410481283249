<!-- <h4 color="primary" class="dialog-headline"><p>Create user</p></h4> -->
<div aria-hidden="true">
    <div class="dialog-headline">
        <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
        <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
    </div>
    <form #organizationModalForm="ngForm" autocomplete="off" data-keyboard="false" data-backdrop="static"
        aria-hidden="true">
        <mat-dialog-content class="mat-typography" style="padding-top: 20px;">



            <div fxLayout="row wrap">

                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field>
                            <input matInput placeholder="Pharmacy NPINo" name="storeNo" #storeNo="ngModel" id="storeNo"
                                [(ngModel)]="OTCBenifits.NPINo" required maxlength="10" minlength="10" mask='0000000000'
                                [disabled]="receivedData.headerTitle=='Update OTC Benefits'"
                                (blur)="checkNPINoExists()">
                            <mat-error *ngIf="storeNo.invalid && (storeNo.dirty || storeNo.touched)"
                                class="invalid-feedback">
                                Please enter Pharmacy NPINo
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

                        <mat-form-field>
                            <input matInput placeholder="Pharmacy Name" name="storeName" #storeName="ngModel"
                                id="storeName" [(ngModel)]="OTCBenifits.StoreName" required appTrimSpaceInput>
                            <mat-error *ngIf="storeName.invalid && (storeName.dirty || storeName.touched)"
                                class="invalid-feedback">
                                Please enter store name
                            </mat-error>
                        </mat-form-field>

                    </div>
                </div>
            </div>

            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

                        <mat-form-field>
                            <mat-select placeholder="Select App" #App="ngModel" name="App" id="App"
                                [(ngModel)]="OTCBenifits.AppId" required>
                                <mat-option *ngFor="let eachApp of AppsArray" [value]="eachApp.appId">
                                    {{ formatAppName(eachApp.appId) }}
                                </mat-option>
                            </mat-select>
                        
                            <mat-error *ngIf="App.invalid && (App.dirty || App.touched)" class="invalid-feedback">
                                Please select an application
                            </mat-error>
                        </mat-form-field>
                        


                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 2%;">
                        <mat-slide-toggle name="NBS"
                            [matTooltip]="OTCBenifits.IsActive?'Disable NBS Benefit':'Enable NBS Benefit'"
                            [(ngModel)]="OTCBenifits.IsNBSAccess">NBS</mat-slide-toggle>

                    </div>
                </div>
            </div>

            <div fxLayout="row wrap">
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 2%;">
                    <div style="padding-bottom: 10px;" fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100"
                        fxFlex="100">
                        <mat-slide-toggle [matTooltip]="OTCBenifits.IsActive?'Disable Pharmacy':'Enable Pharmacy'"
                            name="isActive" [(ngModel)]="OTCBenifits.IsActive">Enable
                            Pharmacy?</mat-slide-toggle>

                    </div>
                    <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
                    <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">

                    </div>
                </div>
            </div>

        </mat-dialog-content>
        <!-- for adding line -->
        <hr>
        <mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">


            <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width: 
        80px;height: 40px;color: #FFFFFF; border-radius: 
        4px;font-size: 14px;text-align: center;line-height: 20px;">
                Close
            </button>

            <button mat-flat-button color="warn" (click)="DeleteOTCBenifitsAccess(OTCBenifits)"
                *ngIf="receivedData.headerTitle=='Update OTC Benefits'" cdkFocusInitial
                [disabled]="organizationModalForm.invalid"
                style="background-color: #6C747E;width: 
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;">Delete</button>

            <button mat-flat-button color="primary" (click)="AddOTCBenifitsAccess(OTCBenifits)"
                *ngIf="receivedData.headerTitle=='Add OTC Benefits'" style="background-color: #F59120;width: 
         80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
                [disabled]="organizationModalForm.invalid" cdkFocusInitial>Create</button>

            <button mat-flat-button color="accent" (click)="UpdateOTCBenifitsAccess(OTCBenifits)"
                *ngIf="receivedData.headerTitle=='Update OTC Benefits'" style="background-color: #F59120;width: 
      80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
                [disabled]="organizationModalForm.invalid" cdkFocusInitial>Update</button>

        </mat-dialog-actions>

    </form>
</div>