<div class="dialog-headline">
  <mat-label style="float:left;">{{receivedData.headerTitle}}</mat-label>
  <mat-icon class="pointer" matTooltip="Close this dialog" mat-dialog-close style="float: right;">close</mat-icon>
</div>
<mat-dialog-content class="mat-typography" style="padding-top: 20px;">
  <form #storeModalForm="ngForm" autocomplete="off">
    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Pharmacy NPINo" name="storeNo" #storeNo="ngModel" id="storeNo"
              [(ngModel)]="Store.NPINo" required disabled (keypress)="keyPressAlphaNumericWithCharacters($event)"
              ng-pattern="[0-9-_!@#$%&*-+()=,.?/';:]">
            <mat-error *ngIf="storeNo.invalid && (storeNo.dirty || storeNo.touched)" class="invalid-feedback">
              Please enter store no.
            </mat-error>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Short Name" name="shortName" #shortName="ngModel" id="shortName"
              [(ngModel)]="pseudoName" disabled>
            <mat-error *ngIf="shortName.invalid && (shortName.dirty || shortName.touched)" class="invalid-feedback">
              Please enter short name
            </mat-error>
          </mat-form-field>

        </div>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Pharmacy Name" name="storeName" #storeName="ngModel" id="storeName"
              [(ngModel)]="Store.storeName" required disabled>
            <mat-error *ngIf="storeName.invalid && (storeName.dirty || storeName.touched)" class="invalid-feedback">
              Please enter store name
            </mat-error>
          </mat-form-field>


        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox [(ngModel)]="Store.isEnabled" name="isEnabled" disabled class="custom-checkbox">
            Enabled
          </mat-checkbox>
        </div>
      </div>
    </div>


    <div fxLayout="row wrap">

      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <div fxFlex.gt-lg="80" fxFlex.gt-md="80" fxFlex.gt-xs="100" fxFlex="100">
            <mat-form-field>
              <input matInput placeholder="User Name" name="userName" #userName="ngModel" id="userName"
                [(ngModel)]="storeData.userName" required [disabled]="receivedData.headerTitle=='Update Pharmacy Admin'"
                appTrimSpaceInput autocomplete="new-password">
              <mat-error *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="invalid-feedback">
                Please enter user name
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex.gt-lg="15" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">
            <label [(ngModel)]="Store.pseudoName" name="pseudoName"><span>@</span>{{pseudoName}}</label>

          </div>

        </div>


        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>

        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Password" name="password" #password="ngModel" id="password"
              [(ngModel)]="Store.password" required [type]="hide ? 'password' : 'text'" autocomplete="new-password">

            <!-- <mat-icon class="pointer" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}
            </mat-icon> -->
            <!-- Commenting out for password hashing <img class="pointer" *ngIf="!hide" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
              src="assets/images/icons/icon-visibility-ON.png" alt="Toggle Password Visibility" (click)="hide = !hide">
            <img class="pointer" *ngIf="hide" width="24px" style="padding-bottom: 10px;text-align: right;" matSuffix
              src="assets/images/icons/icon-visibility-OFF.png" alt="Toggle Password Visibility" (click)="hide = !hide"> Commenting out for password hashing-->

          </mat-form-field>

          <mat-password-strength (onStrengthChanged)="onPasswordStrengthChanged($event);" [externalError]="true"
            [min]="8" [max]="10" #passwordComponent [password]="password.value">
          </mat-password-strength>

          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
              [hideToggle]="showError">
              <mat-expansion-panel-header>
                <mat-panel-description>
                  <mat-icon *ngIf="!panelOpenState">arrow_forward</mat-icon>
                  <mat-icon *ngIf="panelOpenState">arrow_downward</mat-icon>
                  Expand to see errors
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-password-strength-info [passwordComponent]="passwordComponent">
              </mat-password-strength-info>
            </mat-expansion-panel>

          </mat-accordion>
        </div>
      </div>
    </div>

    <div fxLayout="row wrap" style="padding-top: 30px;">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Last Name" name="lName" #lName="ngModel" id="lName" [(ngModel)]="Store.lName"
              required appTrimSpaceInput>
            <mat-error *ngIf="lName.invalid && (lName.dirty || lName.touched)" class="invalid-feedback">
              Please enter last name
            </mat-error>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="First Name" name="fName" #fName="ngModel" id="fName" [(ngModel)]="Store.fName"
              required appTrimSpaceInput>
            <mat-error *ngIf="fName.invalid && (fName.dirty || fName.touched)" class="invalid-feedback">
              Please enter first name
            </mat-error>
          </mat-form-field>

        </div>
      </div>
    </div>

    <div fxLayout="row wrap">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-form-field>
            <input matInput placeholder="Contact No." name="contactNotemp" #contactNotemp="ngModel" id="contactNotemp"
              [(ngModel)]="Store.contactNo" required minlength="10" maxlength="13" mask='(000)000-0000'>
            <mat-error *ngIf="contactNotemp.invalid && (contactNotemp.dirty || contactNotemp.touched)"
              class="invalid-feedback">
              Please enter contact number
            </mat-error>
          </mat-form-field>

        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100" style="padding-top: 20px;">
          <mat-checkbox [(ngModel)]="Store.isActive" name="isActive" class="custom-checkbox">
            Is Active
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div fxLayout="row wrap" style="padding-top:10px;padding-bottom: 15px;">
      <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox [(ngModel)]="Store.changePasswordAtLogin" name="changePasswordAtLogin" class="custom-checkbox">
            Change Password At Login
          </mat-checkbox>
        </div>
        <div fxFlex.gt-lg="5" fxFlex.gt-md="5" fxFlex.gt-xs="100" fxFlex="100"></div>
        <div fxFlex.gt-lg="50" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
          <mat-checkbox [disabled]="!primeRxPOSAdmin.isLocked" [(ngModel)]="primeRxPOSAdmin.isLocked" name="isLocked" class="custom-checkbox"
            [matTooltip]='primeRxPOSAdmin.isLocked ? "Please uncheck the Is Locked? checkbox and update the user to unlock the admin":""'>
            Is Locked?
          </mat-checkbox>
        </div>
      </div>
    </div>



  </form>



</mat-dialog-content>
<!-- for adding line -->
<hr>
<mat-dialog-actions align="end" fxLayout="row" fxLayoutAlign="center center" style="margin: 10px;">
  <button mat-stroked-button mat-dialog-close style="background-color: #6C747E;width:
        80px;height: 40px;color: #FFFFFF; border-radius:
        4px;font-size: 14px;text-align: center;line-height: 20px;">
    Close
  </button>

  <button mat-flat-button color="warn" (click)="DeleteStoreAdmin(Store)"
    *ngIf="receivedData.headerTitle=='Update Pharmacy Admin'" cdkFocusInitial [disabled]="storeModalForm.invalid"
    style="background-color: #6C747E;width:
        80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;">Delete</button>

  <button mat-flat-button color="primary" (click)="InsertStoreAdmin(Store)"
    *ngIf="receivedData.headerTitle=='Create Pharmacy Admin'" style="background-color: #F59120;width:
         80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
    [disabled]="storeModalForm.invalid || !isPasswordStrong" cdkFocusInitial>Create</button>

  <!-- <button mat-flat-button color="primary" (click)="InsertStoreAdmin(Store)"
    *ngIf="receivedData.headerTitle=='Create Pharmacy Admin'" [disabled]="storeModalForm.invalid || !isPasswordStrong"
    cdkFocusInitial>Create</button> -->

  <button mat-flat-button color="primary" (click)="UpdateStoreAdmin(Store)"
    *ngIf="receivedData.headerTitle=='Update Pharmacy Admin'" style="background-color: #F59120;width:
      80px;height: 40px;color: #FFFFFF; border-radius: 4px;font-size: 14px;text-align: center;line-height: 20px;"
    [disabled]="storeModalForm.invalid || !isPasswordStrong" cdkFocusInitial>Update</button>

  <!-- <button mat-flat-button color="primary" (click)="UpdateStoreAdmin(Store)"
    *ngIf="receivedData.headerTitle=='Update Pharmacy Admin'" [disabled]="storeModalForm.invalid || !isPasswordStrong"
    cdkFocusInitial>Update</button> -->

  <!-- <button mat-flat-button *ngIf="receivedData.headerTitle=='Update Pharmacy Admin'" color="warn"
    (click)="DeleteStoreAdmin(Store);" cdkFocusInitial [disabled]="storeModalForm.invalid">
    Delete
  </button> -->

</mat-dialog-actions>
