import { Pagination } from "./Pagination";

export class StoreRequest {

    NPINo = "";
    pseudoName = "";
    isEdiv2: boolean = false;
    isMpos: boolean = false;
    isPrimeRxCloud: boolean = false;
    sortColumn = "";
    sortDirection = "";
    columnType = "";
    pagination: Pagination = new Pagination;
}