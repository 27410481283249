<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" style="gap: 5px;">

        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
            <div fxFlex.gt-lg="15" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">
                <app-page-header [icon]="'assets/images/icons/icon-pharmacies-blue.png'" [title]="'Pharmacies'"
                    [breadcrumb]="''" style="margin: 0; padding: 0;"></app-page-header>
            </div>
            <!-- <div fxFlex.gt-lg="15" fxFlex.gt-md="15" fxFlex.gt-xs="100" fxFlex="100">
                
            </div> -->

            <div fxFlex.gt-lg="12" fxFlex.gt-md="12" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox [(ngModel)]="searchStore.isMpos" name="ePrimePOS" style="margin: 0; padding-top: 5px;"
                    class="custom-checkbox">
                    PrimeRX POS
                </mat-checkbox>
            </div>
            <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox [(ngModel)]="searchStore.isEdiv2" name="EDIV2" style="margin: 0; padding-top: 5px;"
                    class="custom-checkbox">
                    Edi Cloud
                </mat-checkbox>
            </div>
            <div fxFlex.gt-lg="11" fxFlex.gt-md="11" fxFlex.gt-xs="100" fxFlex="100">
                <mat-checkbox [(ngModel)]="searchStore.isPrimeRxCloud" name="PrimeRXCloud"
                    style="margin: 0; padding-top: 5px;" class="custom-checkbox">
                    PrimeRx Cloud
                </mat-checkbox>
            </div>

            <div fxFlex.gt-lg="30" fxFlex.gt-md="30" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;">
                <div fxFlex="auto" fxLayout="row" fxLayoutAlign="end center" class="search-container"
                    style="text-align: right;">
                    <fieldset style="display: flex; align-items: center; gap: 15px;">
                        <input type="text" name="shortName" #shortName="ngModel" id="shortName"
                            [(ngModel)]="searchStore.pseudoName" style="width: 200px;" placeholder="Short Name" />
                        <input type="text" name="storeNo" #organizationName="ngModel" id="storeNo"
                            [(ngModel)]="searchStore.NPINo" style="width: 200px;" placeholder="Pharmacy NPINo"
                            maxlength="10" minlength="10" mask='0000000000' (keypress)="keyPressNumeric($event)" />


                    </fieldset>

                </div>

            </div>
            <div fxFlex.gt-lg="7" fxFlex.gt-md="7" fxFlex.gt-xs="100" fxFlex="100"></div>
            <div fxFlex.gt-lg="10" fxFlex.gt-md="10" fxFlex.gt-xs="100" fxFlex="100">
                <button class="custom-blue-button" type="button" id="btnSearch" style="width: 85px; height: 40px;"
                    (click)="SearchStoreData()">
                    <mat-icon color="white">search</mat-icon>Search
                </button>
            </div>
        </div>



        <div fxFlex="auto" fxLayoutAlign="end center" style="margin: 0; padding: 0;">
            <button class="custom-orange-button" type="button" id="btnOpenCreateOrganizationModal"
                style="width: 100px; height: 45px; margin: 0; padding: 0;" (click)="OpenStoreCreateModal()">
                Create New
            </button>
        </div>

    </div>
</div>

<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">

            <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
                <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                    <div class="table-container" style="width: 100%;">
                        <table mat-table [dataSource]="dataSource" multiTemplateDataRows matSort>


                            <ng-container matColumnDef="Detail">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Detail</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    <mat-icon *ngIf="element.isPrimeRxCloud" class="pointer" matSuffix color="primary"
                                        matTooltip="See PrimeRx Cloud Pharmacy Migration Details"
                                        (click)="showNested = !showNested">{{showNested ?
                                        'expand_less' : 'expand_more'}}
                                    </mat-icon>
                                    <!-- <mat-icon *ngIf="element.isPrimeRxCloud" class="pointer" matSuffix
                                    matTooltip="See PrimeRx Cloud Pharmacy Migration Details" color="primary" (click)="showNested = !showNested">{{showNested ?
                                        'remove' : 'add_circle'}}
                                        <span class="material-symbols-outlined">
                                            
                                        </span>
                                    </mat-icon> -->
                                </td>
                            </ng-container>

                            <!--ROWS COLUMNS-->

                            <ng-container matColumnDef="OrganizationName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Organization Name</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.organizationName}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="NPINo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Pharmacy NPINo</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.NPINo}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="pseudoName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Short Name</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.pseudoName}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="storeName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Pharmacy Name</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.storeName}}
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="subscription">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    Subscription</th>
                                <td mat-cell *matCellDef="let element">
                                    <div style="margin-top: 10px;" *ngIf="element.isMpos">
                                        <epos-status-badge [btnLabel]="'PrimeRX POS'" [needCursor]="false"
                                            [type]="'success'"></epos-status-badge>
                                    </div>
                                    <div style="margin-top: 10px;" *ngIf="element.isEdiv2">
                                        <epos-status-badge [btnLabel]="'Edi Cloud'" [needCursor]="false"
                                            [type]="'pending'"></epos-status-badge>
                                    </div>
                                    <div style="margin-top: 10px;" *ngIf="element.isPrimeRxCloud">
                                        <epos-status-badge [btnLabel]="'PrimeRx Cloud'" [needCursor]="false"
                                            [type]="'inprogress'"></epos-status-badge>
                                    </div>

                                    <div style="margin-top: 5px;">
                                    </div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="isPrimeRxCloudEmptyPharmacy">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    PrimeRx Cloud Empty Pharmacy?</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.isPrimeRxCloudEmptyPharmacy}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="contactName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Contact Name</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.contactName}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="contactNo">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Contact Number</th>
                                <td mat-cell *matCellDef="let element" mask='(000)000-0000' style="padding: 10px;">
                                    ({{ element.contactNo | slice:0:3 }}){{ element.contactNo | slice:3:6 }}-{{
                                    element.contactNo | slice:6 }}
                                </td>
                            </ng-container>
                            <!-- <ng-container matColumnDef="zipCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Zip Code</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    {{element.zipCode}}
                                </td>
                            </ng-container> -->

                            <ng-container matColumnDef="isStoreAdminCreated">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Admin Status</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    <span *ngIf="element.isStoreAdminCreated" style="font-size: small;">
                                        Admin created
                                    </span>
                                    <span *ngIf="!element.isStoreAdminCreated" style="color: #0c7bb8;font-size: small;">
                                        <!-- <epos-outlined-button
                                            matTooltip="Create Admin For EDI and PrimeRX POS Pharmacy"
                                            (click)="CreateAdmin(element)" [btnLabel]="'Create Admin'"
                                            [needCursor]="true" [type]="'info'"></epos-outlined-button> -->
                                        <button mat-icon-button
                                            [matTooltip]="getTooltipText(element.isMpos,element.isEdiv2)"
                                            (click)="CreateAdmin(element)" color="#0c7bb8" style="font-size: small;"
                                            [disabled]="!element.isMpos && !element.isEdiv2">
                                            <mat-icon color="#0c7bb8">add</mat-icon>Create Admin
                                        </button>
                                    </span>
                                </td>
                            </ng-container>


                            <ng-container matColumnDef="Action">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding: 10px;">
                                    Action</th>
                                <td mat-cell *matCellDef="let element" style="padding: 10px;">
                                    <!-- <button mat-icon-button matTooltip="Update Pharmacy"
                                        (click)="OpenStoreUpdateModal(element)" color="primary">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button> -->
                                    <app-table-edit-button [imageSrc]="''" [tooltip]="'Update Pharmacy'"
                                        [color]="'primary'" (buttonClick)="OpenStoreUpdateModal(element)">
                                    </app-table-edit-button>
                                </td>
                            </ng-container>


                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail">
                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                                    <div class="example-element-detail" *ngIf="element.migrationDetails?.data.length"
                                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                        <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">

                                            <table #innerTables mat-table style="border: 1px solid rgb(27, 170, 227);"
                                                [dataSource]="element.migrationDetails">


                                                <ng-container color="primary" matColumnDef="MigrationType">
                                                    <th mat-header-cell color="primary" *matHeaderCellDef>
                                                        Migration Type</th>
                                                    <td mat-cell color="primary" *matCellDef="let element">

                                                        <epos-outlined-button
                                                            *ngIf="element.migrationType=='DrugMigrationToDB'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'info'"></epos-outlined-button>
                                                        <epos-outlined-button
                                                            *ngIf="element.migrationType=='DrugInsertionToElasticSearch'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'success'"></epos-outlined-button>
                                                        <epos-outlined-button
                                                            *ngIf="element.migrationType=='DefaultSchedulars'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'help'"></epos-outlined-button>
                                                        <epos-outlined-button
                                                            *ngIf="element.migrationType=='AsapSetting'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'secondary'"></epos-outlined-button>

                                                        <epos-outlined-button *ngIf="element.migrationType=='Insurance'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'warning'"></epos-outlined-button>

                                                        <epos-outlined-button *ngIf="element.migrationType=='InsuranceToElasticSearch'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'help'"></epos-outlined-button>

                                                        <epos-outlined-button *ngIf="element.migrationType=='SigFile'"
                                                            [btnLabel]="element.migrationType" [needCursor]="false"
                                                            [type]="'tertiary'"></epos-outlined-button>
                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Status">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        Status</th>
                                                    <td mat-cell *matCellDef="let element">

                                                        <epos-status-badge *ngIf="element.status=='PROCESSING'"
                                                            [btnLabel]="element.status" [needCursor]="false"
                                                            [type]="'inprogress'"></epos-status-badge>

                                                        <epos-status-badge *ngIf="element.status=='COMPLETED'"
                                                            [btnLabel]="element.status" [needCursor]="false"
                                                            [type]="'success'"></epos-status-badge>

                                                        <epos-status-badge *ngIf="element.status=='ERROR'"
                                                            [btnLabel]="element.status" [needCursor]="false"
                                                            [type]="'error'"></epos-status-badge>

                                                        <epos-status-badge *ngIf="element.status=='PENDING'"
                                                            [btnLabel]="element.status" [needCursor]="false"
                                                            [type]="'pending'"></epos-status-badge>

                                                    </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Message">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        Message</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{element.message}} </td>
                                                </ng-container>

                                                <ng-container matColumnDef="Option">
                                                    <th mat-header-cell *matHeaderCellDef>
                                                        Retry Option</th>
                                                    <td mat-cell *matCellDef="let migrationdata">
                                                        <button class="custom-orange-button" type="button"
                                                            [id]="'btnRetry' + migrationdata.migrationType"
                                                            style="width: 60x; height: 30px; margin: 0; padding: 0;"
                                                            (click)="RestartMigration(element,migrationdata)">
                                                            Restart
                                                        </button>
                                                    </td>
                                                </ng-container>


                                                <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;">
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns;sticky: true"></tr>
                            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                                [class.example-element-row]="element.migrationDetails?.data.length"
                                [class.example-expanded-row]="expandedElement === element"
                                [ngClass]="{hovered: element.hovered}" (mouseover)="element.hovered = true"
                                (mouseout)="element.hovered = false" (click)="toggleRow(element)">
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row">
                            </tr>


                        </table>
                    </div>

                    <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                        [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                    </mat-paginator>
                </div>


            </mat-card>

        </div>
    </div>
</div>