<div fxLayout="row wrap" style="padding: 15px;">
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">

        <div fxFlex="auto">
            <app-page-header [icon]="'assets/images/icons/Card_Icon.png'" [title]="'OTC Benefits'" [breadcrumb]="''">
            </app-page-header>
        </div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;"></div>
        <div fxFlex.gt-lg="20" fxFlex.gt-md="20" fxFlex.gt-xs="100" fxFlex="100" style="padding-left: 10px;"></div>
        <div fxFlex="auto" fxLayout="row" fxLayoutAlign="end center" class="search-container"
            style="text-align: right;">
            <fieldset>

                <input type="text" placeholder="Pharmacy NPINo" name="NPINo" #NPINo="ngModel" id="NPINo"
                    [(ngModel)]="otcBenifitsSearch.NPINo" color="accent" maxlength="10"
                    (keypress)="keyPressAlphaNumericWithCharacters($event)" style="width: 300px;">
                <button class="search-button" type="button" id="btnOpenCreateOrganizationModal"
                    style="width: 80px; height: 40px;" (click)="SearchOtcBenifits()">
                    <mat-icon>search</mat-icon>
                </button>
            </fieldset>
        </div>

        <div fxFlex="auto" fxLayoutAlign="end center">
            <button class="custom-orange-button" type="button" style="width: 100px; height: 45px;"
                (click)="OpenCreateOtcBenifitsModal()" matTooltip="Add OTC Benefits Access"
                id="btnOpenCreateOtcBenifitsModal">
                Add
            </button>
        </div>

    </div>
</div>



<div fxLayout="row wrap">
    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
        <mat-card class=" mat-elevation-z24" style="background-color: transparent;">
            <div class="mat-elevation-z8 m-12" style="box-shadow: none;">
                <div class="table-container" style="width: 100%;">
                    <table mat-table style="width: 100%;" matSort [dataSource]="dataSource">

                        <ng-container color="primary" matColumnDef="NPINo">
                            <th mat-header-cell color="primary" *matHeaderCellDef mat-sort-header> Pharmacy NPI

                            </th>
                            <td mat-cell color="primary" *matCellDef="let element"> {{element.NPINo}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="StoreName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pharmacy Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.StoreName}}</td>
                        </ng-container>

                        <ng-container matColumnDef="AppId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>App Name </th>
                            <td mat-cell *matCellDef="let element"> {{ formatAppName(element.AppId)}}</td>
                        </ng-container>

                        <ng-container matColumnDef="Subscription">
                            <th mat-header-cell *matHeaderCellDef > Benefit Subscription </th>
                            <td mat-cell *matCellDef="let element" class="center-align">
                                <div style="margin-top: 10px;" *ngIf="element.IsNBSAccess">
                                    <epos-status-badge [btnLabel]="'NBS'" [needCursor]="false"
                                        [type]="'success'"></epos-status-badge>
                                </div>


                                <div style="margin-top: 10px;">
                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="IsActive">
                            <th mat-header-cell *matHeaderCellDef>Pharmacy Enabled? </th>
                            <td mat-cell *matCellDef="let element">

                                <div fxLayout="row" fxLayoutAlign="start center" class="flex-container">
                                    <span [matTooltip]="element.IsActive?'Pharmacy Enabled':'Pharmacy Disabled'">
                                        <mat-slide-toggle [ngClass]="{'readonly-toggle': true}" name="isActive"
                                            [(ngModel)]="element.IsActive">

                                        </mat-slide-toggle>
                                    </span>
                                </div>


                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Action">
                            <th mat-header-cell *matHeaderCellDef> Action </th>
                            <td mat-cell *matCellDef="let element">

                                <app-table-edit-button [imageSrc]="''" [tooltip]="'Update Benefits for '+element.StoreName" [color]="'primary'"
                                    (buttonClick)="OpenUpdateOtcBenifitsModal(element)">
                                </app-table-edit-button>
                                <app-table-delete-button [imageSrc]="''" [tooltip]="'Delete Benefits for '+element.StoreName"
                                    [color]="'primary'" (buttonClick)="DeleteOTCBenifitsAccess(element)">
                                </app-table-delete-button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
                            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"></tr>

                    </table>
                </div>
                <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
                </mat-paginator>
            </div>
        </mat-card>
    </div>
</div>