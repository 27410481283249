import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { OTCBenifits } from 'src/app/models/OTCBenifits';
import { UserAccess } from 'src/app/models/UserAccess';
import { NBSProcessorService } from 'src/app/services/nbsprocessor.service';
import { UserService } from 'src/app/services/user.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-otcbenifits-modal',
  templateUrl: './otcbenifits-modal.component.html',
  styleUrls: ['./otcbenifits-modal.component.css']
})
export class OTCBenifitsModalComponent implements OnInit {

  AppsArray: any = constant.Apps;

  OTCBenifits: OTCBenifits = {
    IsActive: false,
    IsNBSAccess: false,
    IsDeleted: false
  };
  userAccess: any = {};
  userAccessData!: UserAccess;
  checked: boolean = false; // Variable to hold the toggle state

  constructor(@Inject(MAT_DIALOG_DATA) public receivedData: any, public NBSService: NBSProcessorService,
    private utilityService: UtilityService,
    private dialogService: MatDialogRef<OTCBenifitsModalComponent>,private userService: UserService,) {
      this.userAccessData = this.userService.getUsersAccessData();  
    if (receivedData != undefined && receivedData.OTCBenifitsData != undefined) {
      this.OTCBenifits = receivedData.OTCBenifitsData;
    }
  }

  ngOnInit(): void {
  }

  formatAppName(appId: string): string {
    if (appId === 'PRIMERXPOS') {
      return 'PrimeRx POS';
    } else if (appId === 'PRIMEPOS') {
      return 'Prime POS';
    }
    return appId; // Default case if no match
  }
  
  onToggleClick() {
    this.OTCBenifits.IsActive = this.checked;
  }

  checkNPINoExists() {

    if (this.OTCBenifits != undefined && this.OTCBenifits.NPINo != undefined && this.OTCBenifits.NPINo != "" && this.OTCBenifits.NPINo != null) {


      this.NBSService.checkNPINoExists(this.OTCBenifits).subscribe({
        next: (response) => {
          if (response.result == ResponseStatus.SUCCESS) {


          } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
            this.OTCBenifits.NPINo = "";
          }
        },
        error: (errorResponse) => {
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
        }
      });

    }
  }

  AddOTCBenifitsAccess(OTCBenifits: OTCBenifits) {
    if (!OTCBenifits) {
        this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Invalid OTC Benefits data.");
        return;
    }

    OTCBenifits.CreatedBy = this.userAccessData?.userName;

    this.NBSService.AddOTCBenifitsAccess(OTCBenifits).subscribe({
        next: (response) => {
            if (!response) {
                this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Unexpected response from server.");
                return;
            }

            switch (response.result) {
                case ResponseStatus.SUCCESS:
                    this.utilityService
                        .showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "OTC Benefit added successfully")
                        .subscribe(() => this.dialogService.close(true));
                    break;

                case ResponseStatus.FAILURE:
                    this.handleInactiveStore(response.data, OTCBenifits);
                    break;

                case ResponseStatus.ERROR:
                    this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
                    OTCBenifits.NPINo = "";
                    break;

                default:
                    this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Unknown response from server.");
            }
        },
        error: (error) => {
            const errorMessage = error?.message || "An unexpected error occurred.";
            this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, errorMessage);
        }
    });
  }

/**
 * Handles inactive store response and prompts the user to reactivate the store.
 */
  private handleInactiveStore(responseData: any, OTCBenifits: OTCBenifits) {
      try {
          const inactiveStore = JSON.parse(responseData);
          if (Array.isArray(inactiveStore) && inactiveStore.length > 0) {
              const store = inactiveStore[0];
              OTCBenifits.mId = store.mId;
              OTCBenifits.IsDeleted = false;
              OTCBenifits.IsActive = true;
              OTCBenifits.UpdatedBy = this.userAccessData?.userName;

              this.utilityService
                  .openPrompt("Inactive OTC Benefit", "Do you want to reactivate the OTC benefit for store "+ OTCBenifits.NPINo +"?\n(If NO, it will not be created)")
                  .subscribe((userChoice) => {
                      if (userChoice === true) {
                          this.ReactivateOTCBenifitsAccess(OTCBenifits);
                      } else {
                          this.dialogService.close(true);
                      }
                  });
          } else {
              this.utilityService.showAlert(
                  constant.KEY_ERROR,
                  constant.ALERT_FAILED_TITLE,
                  "Could not retrieve inactive store data."
              );
          }
      } catch (error) {
          this.utilityService.showAlert(
              constant.KEY_ERROR,
              constant.ALERT_FAILED_TITLE,
              "Error processing inactive store information."
          );
      }
  }


  UpdateOTCBenifitsAccess(OTCBenifits: OTCBenifits) {
    OTCBenifits.UpdatedBy = this.userAccessData.userName;
    this.NBSService.UpdateOTCBenifitsAccess(OTCBenifits).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "OTC Benefit updated successfully").subscribe(result => {
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
        }
      },
      error: (errorResponse) => {
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  ReactivateOTCBenifitsAccess(OTCBenifits: OTCBenifits) {

    this.NBSService.UpdateOTCBenifitsAccess(OTCBenifits).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, "Reactivated", "OTC Benefit reactivated successfully").subscribe(result => {
            //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
            this.dialogService.close(true);
          });
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
        }
      },
      error: (errorResponse) => {
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  DeleteOTCBenifitsAccess(OTCBenifits: OTCBenifits) {

    this.utilityService.openPrompt("Are you sure?", "You won't be able to revert this!")
      .subscribe(userConfirmed => {
        if (!userConfirmed) return; // Exit if user cancels
        this.NBSService.DeleteOTCBenifitsAccess(OTCBenifits).subscribe({
          next: (response) => {
            if (response.result == ResponseStatus.SUCCESS) {
    
              this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_DELETE_TITLE, "OTC Benefit deleted successfully").subscribe(result => {
                //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                this.dialogService.close(true);
              });
            } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
    
              this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
            }
          },
          error: (errorResponse) => {
            this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
          }
        });
      });

    

  }

}
