import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { resourceUsage } from 'process';
import { Vendor } from 'src/app/models/Vendor';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { ContractDetail, VendorWithContract, VendorWithContracts } from '../vendor-mapping.component';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';

@Component({
  selector: 'app-contract-modal',
  templateUrl: './contract-modal.component.html',
  styleUrls: ['./contract-modal.component.css']
})
export class ContractModalComponent implements OnInit {
  hideOldPwd: boolean = true;
  vendor: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810: '',
    processSwitch832: '',
    processSwitch835: '',
    processSwitch855: ''
  };
  contract: any = {
    contractName: '',
    contractUsername: '',
    contractPassword: '',
    contractIsaInterchangeSenderId: '',
    contractAppSenderCode: '',
    contractIdentificationCodeBy: '',
    contractPriceFileFormat: '',
    contractAckFileFormat: '',
    contractFileFormat810: '',
    contractOutboundDirName: '',
    contractInboundDirName: '',
    useQntPack:false
  };
  vendorWithContract: VendorWithContracts = {
    vendor: this.vendor,
    contractDetail: this.contract,
  };

  NPINo!: string;
  vendorCode!: string;
  // url!: string;
  // port!: string;
  // connType!: string;
  // inboundDir!: string;
  // outboudDir!: string;
  constructor(@Inject(MAT_DIALOG_DATA) public item: any, private utilityService: EdiUtilityService, private vendorService: VendorService, private dialogService: MatDialogRef<ContractModalComponent>) {
  }

  ngOnInit(): void {
    this.NPINo = this.item.NPI;
    this.vendorCode = this.item.vendorCode;
    // this.url = this.item.url;
    // this.port = this.item.port;
    // this.connType = this.item.connType;
    // this.inboundDir = this.item.inboundDir;
    // this.outboudDir = this.item.outboudDir;
  }

  createContract() {
    this.vendor.NPINo = this.NPINo;
    this.vendor.vendorCode = this.vendorCode;
    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.contractDetail = this.contract;


    this.vendorService.checkIfContractAlreadyExists(this.vendorWithContract).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var res = JSON.parse(response.data)
          if (!res.Item1) {
            this.vendorService.createVendorContract(this.vendorWithContract).subscribe({
              next: (response) => {
                if (response.result == ResponseStatus.SUCCESS) {

                  this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_CREATE_TITLE, "Account created successfully").subscribe((result: any) => {

                    //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                    this.dialogService.close(true);
                  });
                } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {
                  this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, 'Account name already exists');
                } else {
                  this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, "Error occurred while creating the account");
                }
              },
              error: (errorResponse) => {
                console.log(errorResponse);
                this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
              }
            });
          }
          else if (res.Item2) {
            this.utilityService.showContractReplacementAlert(constant.KEY_WARNING, "Account with same name already have been created", "Click on 'Replace' if you want to activate previous account with updated fields or click on 'Cancel' and provide unique account name.")
              .subscribe(result => {
                if (result.event == 'Replace') {
                  this.vendorService.createVendorContract(this.vendorWithContract).subscribe({
                    next: (response) => {
                      if (response.result == ResponseStatus.SUCCESS) {
                        this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_UPDATE_TITLE, "Account updated successfully").subscribe((result: any) => {

                          //NOT USING THE RESULT IF THE USER CLICKS ON BACKGROUND THEN RESULT WILL BE UNDEFINED
                          this.dialogService.close(true);
                        });

                      } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

                        this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, 'Failed to update account');
                      }
                    },
                    error: (errorResponse) => {
                      console.log(errorResponse);
                      this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
                    }
                  });
                }
              }
              );
          }
          else if (!res.Item2) {
            this.utilityService.showAlert(constant.KEY_WARNING, constant.ALERT_WARNING_TITLE, "Account with same name is already active.").subscribe((result: any) => {
              // this.dialogService.close(true);
            });
          }
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  testVendorFtpSftpConnection() {
    this.vendor.NPINo = this.NPINo;
    this.vendor.vendorCode = this.vendorCode;
    this.vendorWithContract.vendor = this.vendor;
    this.vendorWithContract.contractDetail!.contractUsername = this.contract.contractUsername;
    this.vendorWithContract.contractDetail!.contractPassword = this.contract.contractPassword;

    this.vendorService.testVendorFtpConnection(this.vendorWithContract).subscribe({
      next: (response) => {
        var receivedConnectionResponse = JSON.parse(response.data);
        var message = "";
        if (response.result == ResponseStatus.SUCCESS) {
          if (receivedConnectionResponse.isFtpConnected == true) {

            if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == true) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory exists.\n" +
                "Outbound directory exists.\n";
            } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == true) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory does not exist.\n" +
                "Outbound directory exists.\n";
            } else if (receivedConnectionResponse.isInBoundDirPresent == true && receivedConnectionResponse.isOutBoundDirPresent == false) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory  exists.\n" +
                "Outbound directory does not exist.\n";
            } else if (receivedConnectionResponse.isInBoundDirPresent == false && receivedConnectionResponse.isOutBoundDirPresent == false) {

              message = "FTP/SFTP connection tested successfully.\n" +
                "Inbound directory does not exist.\n" +
                "Outbound directory does not exist. \n";
            }
          } else if (receivedConnectionResponse.isFtpConnected == false) {

            this.utilityService.showAlert(
              constant.KEY_ERROR,
              constant.ALERT_FAILED_TITLE,
              "FTP connection failed"
            );
          }

          this.utilityService.showAlert(
            constant.KEY_SUCCESS_WITH_NO_RELOAD,
            "Info",
            message
          );

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error occurred while testing the connection");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(
          constant.FAILURE,
          constant.ALERT_FAILED_TITLE,
          errorResponse
        );

      }
    });

  }
}
