import { Injectable } from '@angular/core';
import { EDIUser } from 'src/app/models/EDIUser';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { EdiUserService } from 'src/app/services/edi_user.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserService } from 'src/app/services/user.service';

export interface SubChildMenu {
  state: string;
  name: string;
  type: string;
  icon: string;
}

export interface ChildMenu {
  state: string;
  name: string;
  type: string;
  icon: string;
  isHovered: boolean;
  subChildMenu: SubChildMenu[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  imageUrl: string;
  expanded: boolean;
  isHovered: boolean;
  childMenu: ChildMenu[];
}




var retMenuData: Menu[] = [];

@Injectable()
export class MenuItems {

  getMenuitem(): Menu[] {
    this.roleType = sessionStorage.getItem('roleType');

    return retMenuData;
  }
  userAccessData!: EdiUserAccess;
  childMenuData: ChildMenu[] = [];
  roleType: any;
  user: EDIUser;
  ediuserRequest: any = {};


  constructor(private userService: UserService, private tokenStorageService: TokenStorageService, private ediuserService: EdiUserService) {


  }
  // ediUserLogin(){

  //   this.ediuserService.loginUser(this.ediuserRequest).subscribe(result => {
  //     // if (result != false) {
  //     //   this.dbVerifiedUser = JSON.parse(result.data);

  //     //   if (this.dbVerifiedUser) {
  //     //     this.router.navigateByUrl('/home');
  //     //   }
  //     // }
  //   });

  // }
  setAccessLinks() {
    this.user = this.tokenStorageService.getUser();

    if (!this.userAccessData) {
      this.userAccessData = {} as any;
    }

    // this.userAccessData.NPINo = user.NPINo;
    // this.userAccessData.pseudoName = user.pseudoName;

    // this.user.appId = [];
    // this.user.appId.push("EDIV2Web");
    // this.ediuserRequest = {
    //   user: this.user,
    //   appId: this.user.appId
    // }

    // this.ediUserLogin();

    this.userAccessData = this.userService.getUsersAccessData();
    this.userAccessData.userRights = JSON.parse(this.userAccessData.userRights);
    retMenuData.length = 0;

    this.roleType = sessionStorage.getItem('roleType');

    if (this.roleType == 'MMSAdministrator') {

      this.childMenuData = [];

      retMenuData.push({ state: 'organization', type: 'link', name: 'Organizations', icon: 'business', imageUrl: 'assets/images/icons/icon-organization.png', expanded: false, isHovered: false, childMenu: this.childMenuData });
      retMenuData.push({ state: 'store', type: 'link', name: 'Pharmacies', icon: 'store', imageUrl: 'assets/images/icons/icon-store.png', expanded: false, isHovered: false, childMenu: this.childMenuData });
      retMenuData.push({ state: 'storeAdmin', type: 'link', name: 'Pharmacy Admins', icon: 'admin_panel_settings', imageUrl: 'assets/images/icons/icon-pharmacyadmin.png', expanded: false, isHovered: false, childMenu: this.childMenuData });

      retMenuData.push({ state: 'otcBenefits', type: 'link', name: 'OTC Benefits', icon: 'credit_card', expanded: false, imageUrl: 'assets/images/icons/Card_Icon.png', isHovered: false, childMenu: this.childMenuData });
      //retMenuData.push({ state: 'serviceProvider', type: 'link', name: 'Pharamcy Provider Configuration', icon: 'credit_card', expanded: false, imageUrl: 'assets/images/icons/icon-provider.png', isHovered: false, childMenu: this.childMenuData });
      
      //For Provider Configuration
      this.childMenuData = [];
      this.childMenuData.push({ state: 'serviceProvider', type: 'link', name: 'Pharmacy Provider Configuration', icon: 'view_comfy', isHovered: false, subChildMenu: [] });
      if (this.childMenuData.length > 0) {
        retMenuData.push({ state: 'serviceProvider', type: 'text', name: 'PrimeCommunication', icon: 'analytics', imageUrl: 'assets/images/icons/Icon-service-config.png', expanded: false, isHovered: false, childMenu: this.childMenuData });
      }

      //For EDI
      this.childMenuData = [];
      this.childMenuData.push({ state: 'edi/vendorMaster', type: 'link', name: 'Vendor Master', icon: 'view_comfy', isHovered: false, subChildMenu: [] });
      this.childMenuData.push({ state: 'edi/vendorMapping', type: 'link', name: 'Vendor Mapping', icon: 'view_comfy', isHovered: false, subChildMenu: [] });
      this.childMenuData.push({ state: 'edi/fileDownload', type: 'link', name: 'File Download', icon: 'view_comfy', isHovered: false, subChildMenu: [] });
      this.childMenuData.push({ state: 'edi/fileParsingError', type: 'link', name: 'File Parsing Error', icon: 'view_comfy', isHovered: false, subChildMenu: [] });
      this.childMenuData.push({ state: 'edi/scheduler', type: 'link', name: 'Scheduler Configuration', icon: 'view_comfy', isHovered: false, subChildMenu: [] });
      if (this.childMenuData.length > 0) {
        retMenuData.push({ state: 'menu', type: 'text', name: 'EDI', icon: 'analytics', imageUrl: 'assets/images/icons/icon-edi.png', expanded: false, isHovered: false, childMenu: this.childMenuData });
      }
      
      retMenuData.push({ state: 'userManagement', type: 'link', name: 'User Management', icon: 'admin_panel_settings', imageUrl: 'assets/images/icons/icon-pharmacyadmin.png', expanded: false, isHovered: false, childMenu: [] });

    } else {
      retMenuData.push({ state: 'adminPage', type: 'link', name: 'Pharmacy Info', icon: 'admin_panel_settings', imageUrl: 'assets/images/icons/icon-pharmacyadmin.png', expanded: false, isHovered: false, childMenu: this.childMenuData });
    }

    
  }


}
