import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class constant {

  //http header variable
  public static httpClientHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Origin, X-Auth-Token,X-Requested-With,Content-Type',
      'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
    })
  }

  public static getHttpClientHeaderWithToken(token: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Origin, X-Auth-Token,X-Requested-With,Content-Type',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        'Authorization': `Bearer ${token}`
      })
    };
  }

  public static getHttpClientHeaderForToken(){
    return {
      headers: new HttpHeaders({
        'Authorization': 'Basic cnlsaW5vMnVyVmxGdHNsY2ZRanhydmM3c0RIcTlqNDg6Q3l3aklVc1M1dXR4QWlQWldFMjVOZ2ZuUGw4LUppMjFXbEVucXRUeGNEM3lmVXlGNWFVVFlfUmJwbWlSaWVueQ==',
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
  }


  public static baseURL: string = environment.baseURL;
  public static basePrimeComTokenURL = environment.basePrimeComTokenURL;
  public static basePrimeComURL =environment.basePrimeComURL;
  public static gateWayPort: string = ":9030";

  public static ItemTag: string = "/ePrimePOSItemService"; //#EPPOS-3788
  public static StateMasterTag: string = "/StateMasterService"; //#EPPOS-3755
  //
  //region local url with auth
  public static MPOS_USER_SERVICE_URL: string = this.baseURL + "/ePrimePOSUsersService";
  public static MPOS_STORE_SERVICE_URL: string = this.baseURL + "/ePrimePOSStoreService";
  public static MANAGE_RIGHTS_SERVICE_URL: string = this.baseURL + this.gateWayPort;
  public static ROLE_SERVICE_URL: string = this.baseURL + "Storeservice";
  public static EDI_STORE_SERVICE_URL: string = this.baseURL + "/ediStoreService";
  public static EDI_USER_SERVICE_URL: string = this.baseURL + "/ediUserService";
  public static STORECONFIG_STORE_SERVICE_URL: string = this.baseURL + "/storeConfigStoreservice";
  public static STORECONFIG_USER_SERVICE_URL: string =  this.baseURL + "/storeConfigUserservice";
  public static NBS_SERVICE_URL: string = this.baseURL + "/NBSProcessorService";

  public static ONBOARDING_SERVICE_URL: string = this.baseURL + "/OnBoardingService";

  //public static STORECONFIG_USER_SERVICE_URL: string="http://localhost:8097";

  public static ORGANIZATION_SERVICE_URL: string = this.baseURL + "/OrganizationService";

  public static PHARMACY_SERVICE_URL: string = this.baseURL + "/OrganizationService";
  public static PAYTYPE_MASTER_URL: string = this.baseURL + "/ePrimePOSPaytypeMasterService";



  public static VENDOR_SERVICE_URL: string = this.baseURL + this.gateWayPort;
  public static VENDOR_POLLER_SERVICE_URL: string = this.baseURL + this.gateWayPort;
  public static VENDOR_PRICE_UPDATE_SERVICE_URL: string = this.baseURL + this.gateWayPort;

  public static FILEHISTORY_SERVICE_URL: string = this.baseURL + this.gateWayPort;
  public static FILEDOWNLOAD_SERVICE_URL: string = this.baseURL + this.gateWayPort;

  //For Messaging service provider api
  //public static PROVIDER_CONFIGURATION_SERVICE_URL: string = "/api/ProviderConfiguration";
  public static PROVIDER_CONFIGURATION_SERVICE_URL: string = "";
  //public static OAUTH_TOKEN_SERVICE_URL: string = "/oauth/token";
  //public static OAUTH_AUDIENCE: string = "https://api.dev.eprimerx.com";
  //public static GET_PROVIDER_CONFIGURATION: string = "/Get?api-version=1";
  //public static ADD_PROVIDER_CONFIGURATION: string = "/api/v1/ProviderConfiguration/Add";
  public static UPDATE_PROVIDER_CONFIGURATION: string =this.basePrimeComURL + "/api/v1/ProviderConfiguration/Update";
  public static SEARCH_PROVIDER_CONFIGURATION: string = this.basePrimeComURL + "/api/v1/ProviderConfiguration/Search";
  public static DELETE_PROVIDER_CONFIGURATION: string =this.basePrimeComURL + "/api/v1/ProviderConfiguration/Delete";
  //public static GET_PHARMACY_BY_NPI: string = this.PROVIDER_CONFIGURATION_SERVICE_URL + "/Find";
  public static INSERT_PROVIDER_CONFIGURATION: string = this.basePrimeComURL + "/api/v1/ProviderConfiguration/Add";
  //public static GET_OAUTH_TOKEN: string = this.OAUTH_TOKEN_SERVICE_URL;
  public static GET_ALL_PROVIDERS: string = this.basePrimeComURL + "/api/v1/ProviderConfiguration/GetProvider";
  public static GET_PHARMACY_DETAILS: string = this.basePrimeComURL + "/api/v1/Pharmacy/GetPharmacy";
  //endregion

  public static LOGIN_URL = "/User/AuthenticateByPseudoName";
  public static LoginUsingAuth0_URL = "/User/Authenticate";
  public static GET_ALL_STORES_URL = "/Stores/GetAllStores";
  public static refreshToken: string = "/User/refresh-token";
  public static GET_STORE_ON_SEARCH = "/Store/getStore";
  public static INSERT_STORE = "/Store/insertStore";
  public static UPDATE_STORE = "/Store/updateStore";
  public static DELETE_STORE = "/Store/deleteStore";
  public static GET_STORE_AND_ADMIN = "/Store/getStoreAndUserOnLoad";
  public static INSERT_EPRIMERX_STORE = "/Store/CreateTenant";
  public static CHECK_NPINO_STORE = "/Store/CheckNPINoExists";
  public static CHECK_SHORTNAME_STORE = "/Store/CheckShortNameExists";
  public static GET_STORE_BY_ORGANIZATION = "/Store/getStoreOrgId";
  public static RollBack_STORE = "/Store/RollBackStoreCreation";

  public static UPDATE_EDI_SETTIGNS = "/OnBoarding/UpdateEDISettings";

  //#region EPPOS-3788
  public static insertDefaultItem: string = constant.baseURL + this.ItemTag + "/Items/insertDefaultItem";
  // public static insertDefaultItem: string = "http://localhost:8013/Items/insertDefaultItem";
  //#endregion

  //#region EPPOS-3755
  public static stateCityByZip: string = constant.baseURL + this.StateMasterTag + "/StateMaster/stateCityByZip"
  // public static stateCityByZip: string = "http://localhost:8097/StateMaster/stateCityByZip"
  //#endregion

  public static INSERT_EDI_STORE = "/Store/InsertStore";
  public static UPDATE_EDI_STORE = "/Store/updateStoreFromStoreConfig";
  public static DELETE_EDI_STORE = "/Store/DeleteStore";
  public static DELETE_EDI_STORE_ACCESS = "/Store/deleteEDIStoreAndUserAccess";

  public static INSERT_MPOS_STORE = "/Store/InsertStore";
  public static UPDATE_MPOS_STORE = "/Store/updateStoreFromStoreConfig";
  public static DELETE_MPOS_STORE = "/Store/DeleteStore";
  public static DELETE_MPOS_STORE_ACCESS = "/Store/deleteMPOSStoreAndUserAccess";

  public static GET_ALL_STORES_ADMIN_ON_LOAD = "/User/getStoreAdminOnLoadPagination";
  public static GET_STORE_ADMIN_ON_SEARCH = "/User/getStoreAdminUser";
  public static INSERT_STORE_ADMIN = "/User/createStoreAdmin";
  public static UPDATE_STORE_ADMIN = "/User/updatePharmacyAdminUser";
  public static DELETE_STORE_ADMIN = "/User/deletePharmacyAdminUser";
  public static GET_STORE_ADMIN_BY_NPI = "/User/getExistingUserByNPINo";
  public static GET_STORE__BY_NPI = "/Store/getStoreByID";

  public static GET_ALL_MMS_ADMIN_ON_LOAD = "/User/getMMSAdminOnLoadPagination";
  public static GET_MMS_ADMIN_ON_SEARCH = "/User/getMMSAdminUser";
  public static INSERT_MMS_ADMIN = "/User/createMMSAdmin";
  public static UPDATE_MMS_ADMIN = "/User/updateMMSAdminUser";
  public static DELETE_MMS_ADMIN = "/User/deleteMMSAdminUser";

  //ORGANIZATION
  public static GET_ALL_ORGANIZATIONS = "/Organization/GetOrganization";
  public static GET_ORGANIZATION_ON_SEARCH = "/Organization/getStoreAdminUser";
  public static INSERT_ORGANIZATION = "/Organization/CreateOrganization";
  public static UPDATE_ORGANIZATION = "/Organization/UpdateOrganization";
  public static DELETE_ORGANIZATION = "/Organization/DeleteOrganization";

  //PAYTYPE
  public static GETMASTERPAYTYPE = this.PAYTYPE_MASTER_URL + "/PaytypeMaster/getPaytypeMasterAsync"
  //public static GETMASTERPAYTYPE = "http://localhost:8021/PaytypeMaster/getPaytypeMasterAsync"
  public static GETMASTERPAYTYPEBYNPI = this.PAYTYPE_MASTER_URL + "/PaytypeMaster/getPaytypeMasterByNPI"
  public static INSERTMASTERPAYTYPE = this.PAYTYPE_MASTER_URL + "/PaytypeMaster/InsertPaytypeMaster"
  public static INSERTMANYMASTERPAYTYPE = this.PAYTYPE_MASTER_URL + "/PaytypeMaster/InsertManyPaytypeMaster"
  //public static INSERTMANYMASTERPAYTYPE ="http://localhost:8021/PaytypeMaster/InsertManyPaytypeMaster"
  //public static INSERTMASTERPAYTYPE ="http://localhost:8021/PaytypeMaster/InsertPaytypeMaster"
  public static UPDATEMASTERPAYTYPEBYNPI = this.PAYTYPE_MASTER_URL + "/PaytypeMaster/UpdatePaytypeMaster"
  //public static UPDATEMASTERPAYTYPEBYNPI ="http://localhost:8021/PaytypeMaster/UpdatePaytypeMaster"
  public static DELETEMASTERPAYTYPEBYNPI = this.PAYTYPE_MASTER_URL + "/PaytypeMaster/DeletePaytypeMasterByNpi"
  //public static DELETEMASTERPAYTYPEBYNPI ="http://localhost:8021/PaytypeMaster/DeletePaytypeMasterByNpi"

  //PHARMACY
  public static GET_PHARMACY = "/Pharmacy/GetPharmacy";
  public static INSERT_PHARMACY = "/Pharmacy/CreatePharmacy";
  public static UPDATE_PHARMACY = "/Pharmacy/UpdatePharmacy";
  public static DELETE_PHARMACY = "/Pharmacy/DeletePharmacy";
  public static GET_MASTERDB = "/Pharmacy/GetMasterDBName";
  public static GET_CITIES = "/Pharmacy/GetCityByStateCode";
  public static RESTART_DB_MIGRATION = "/Pharmacy/RestartDBMigration";//EPPOS-3834

  public static INSERT_EDI_STORE_ADMIN = "/User/createPharmacyAdmin";
  public static UPDATE_EDI_STORE_ADMIN = "/User/updatePharmacyAdminUser";
  public static DELETE_EDI_STORE_ADMIN = "/User/deletePharmacyAdminUser";

  public static INSERT_MPOS_STORE_ADMIN = "/Users/createPharmacyAdmin";
  public static UPDATE_MPOS_STORE_ADMIN = "/Users/updateStoreAdminFromStoreConfigAndEdi";
  public static RESET_PASSWORD_MPOS_STORE_ADMIN = "/Users/UpdatePassword";
  public static DELETE_MPOS_STORE_ADMIN = "/Users/deletePharmacyAdminUser";
  public static GET_MPOS_STORE_ADMIN_BY_USERNAME = "/Users/getUserByUserName";

  //NBS
  public static CHECK_NBS_NPINO_STORE = "/NBS/CheckNPINoExists";
  public static GET_OTCBENIFITS_PHARMACY = "/NBS/GetOTCBenifitsPharmacyList";
  public static DELETE_OTCBENIFIT_ACCESS = "/NBS/DeleteOTCBenifitAccess";
  public static UPDATE_OTCBENIFIT_ACCESS = "/NBS/UpdateOTCBenifitAccess";
  public static ADD_OTCBENIFIT_ACCESS = "/NBS/AddOTCBenifitAccess";
  public static SEARCH_OTCBENIFITS_PHARMACY = "/NBS/GetOTCBenifitsPharmacy";

  public static STORECONFIGURATOR: string = 'StoreConfigurator';
  public static SUCCESS: string = 'SUCCESS';
  public static FAILURE: string = 'ERROR';


  public static getRolesOnLoadUrl: string = "/roles/getRolesAsyncByNPI";
  public static getUsersUrl: string = "/Users/getUsersAsyncByNPI";
  public static getRolesByRightUrl: string = "/ManageRights/getManageRightsByID";
  public static getEDIV2UsersUrl: string = "/User/getUsersAsyncByNPI";
  public static getFileDownloadDataUrl: string = "/FileDownload/getFileDownloadData";
  public static getVendorsByNPIUrl: string = "/Vendor/getAllVendorsByNPI";
  public static getVendorPollerServiceUrl: string = "/VendorPoller/Secret";

  public static userTag: string = "edivUserService";


  keyPressAlphaNumericWithCharacters(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9-_!@#$%&*()-+=,.?/'";: ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public static KEY_SUCCESS = 'SUCCESS';
  public static KEY_SUCCESS_WITH_NO_RELOAD = 'SUCCESS_NO_RELOAD';
  public static KEY_ERROR = 'ERROR';
  public static KEY_WARNING = 'WARNING';
  public static KEY_FAILURE = 'FAILURE';
  public static ALERT_WARNING_TITLE = 'Warning';
  public static ALERT_CREATE_TITLE = 'Created';
  public static ALERT_UPDATE_TITLE = 'Updated';
  public static ALERT_DELETE_TITLE = 'Deleted';
  public static ALERT_FAILED_TITLE = 'Failed';
  public static ALERT_SUCCESS_TITLE = 'Success';
  public static ALERT_TIMEOUT_TITLE = 'Time out';
  public static KEY_STATION_DETAILS = 'stationDetail';

  //#region Domains
  public static DOMAINS: string[] = ["micromerchantsystems.com", "primerx.io"];
  //#endregion

  //SESSION VARIABLES
  public static TOKEN_KEY = 'auth-token';
  public static AUTH0_TOKEN_KEY = 'auth0-token';
  public static REFRESHTOKEN_KEY = 'auth-refreshtoken';
  public static USER_KEY = 'auth-user';
  public static USER_NAME = 'userName';
  public static NPINO = 'NPINo';
  public static USER_PSEUDONAME = 'USERPSEUDONAME';
  //API NAMES
  public static STORE_CONFIG_STORE_SERVICE: string = "StoreConfigStoreService";
  public static EDI_STORE_SERVICE: string = "EdiStoreService";
  public static POS_CLOUD_STORE_SERVICE: string = "EprimePOSStoreService";
  public static PRIMERRX_CLOUD_STORE_SERVICE: string = "PrimeRxCloudStoreService";

  public static deleteUserUrl: string = constant.baseURL + constant.userTag + "/User/DeleteUsers";

  public static CUSTOM_PAYMENT = "CUSTOM_PAYMENT";

  public static Apps: any[] = [
    {
      appId: "PRIMEPOS"
    },
    {
      appId: "PRIMERXPOS"
    }
  ]

}

export enum GenSys {
  UseRegularRxSequenceonly = 1,
  UseControlandRegularSequence = 5,
  UseControlNarcoticsandRegularSequence = 6,
  UseNarcoticsandRegularSequence = 7,
}

export interface ApiResult {
  apiName: string;
  result: any;
}


export enum MigrationStatus {
  PENDING,
  INQUEUE,
  PROCESSING,
  INPROGRESS,
  COMPLETED,
  ERROR
}

export enum ProcessorType {
  DrugMigrationToDB,
  DrugInsertionToElasticSearch,
  DefaultSchedulars,
  AsapSetting,
  SigFile,
  Insurance
}


