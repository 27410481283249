import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { constant } from '../constant/constant';
import { Auth } from '../models/Auth';
import { TokenStorageService } from '../services/token-storage.service';
import { UtilityService } from '../services/utility.service';
import { UserService } from '../services/user.service';
import { ResponseStatus } from '../models/ApiResponse';
import { EdiUserService } from '../services/edi_user.service';
import { AuthService as Auth0Service } from '@auth0/auth0-angular'
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']

})


export class LoginComponent implements OnInit {

  user: any = {};
  loggedIn = false;
  token: string | undefined;
  AppIDList: any = [];
  userRequest: any = {};
  hide: boolean = true;

  myFunction() {
    this.hide = !this.hide;
  }

  ediuserRequest: any = {};
  dbVerifiedUser: any = {};

  constructor(private http: HttpClient, private router: Router, private tokenStorageService: TokenStorageService,
    private utilityService: UtilityService, private userService: UserService, private ediuserService: EdiUserService,
    private auth: Auth0Service, private route: ActivatedRoute, private authService: AuthService) { this.token = undefined; }

  ngOnInit(): void {
    if (this.authService.isLoggedInValue) {
      this.auth.user$.subscribe(user => {
        if (user) {
          this.auth.getAccessTokenSilently().subscribe(token => {
            this.userRequest = {
              user: {
                ...this.user,
                userName: user.email,
                appId: [constant.STORECONFIGURATOR]
              },
              authToken: token,
              appId: [constant.STORECONFIGURATOR]
            };
            this.userService.loginUsingAuth0(this.userRequest).subscribe({
              next: (response) => {
                if (response.result == ResponseStatus.SUCCESS) {
                  var receivedResponse = JSON.parse(response.data);
                  let userDetails = receivedResponse.user;
                  // userDetails.pseudoName = receivedResponse.storeDetails.pseudoName;

                  receivedResponse.user.pseudoName = userDetails.pseudoName;
                  this.tokenStorageService.saveToken(receivedResponse.token);
                  this.tokenStorageService.saveRefreshToken(receivedResponse.user.refreshToken);
                  this.tokenStorageService.saveUser(receivedResponse.user);
                  let auth0token: string = receivedResponse.authToken;
                  this.tokenStorageService.saveAuth0Token(auth0token);

                  if (userDetails.role == 'MMSAdministrator') { 
                    sessionStorage.setItem('roleType', 'MMSAdministrator');
                    this.router.navigateByUrl('/organization');
                  } else {
                    sessionStorage.setItem('roleType', 'Administrator');
                    this.router.navigateByUrl('/adminPage');
                  }

                } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

                  if (response.data != undefined && response.data != null) {
                    const errorMsg = JSON.parse(response.data);


                    this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, errorMsg.Message)
                  } else {

                    this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
                  }
                }
              },
              error: (errorResponse) => {
                console.log(errorResponse);
                this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
              }
            });
          });
        }
      });
    }
  }



  loginUser() {
    this.AppIDList.length = 0;
    this.AppIDList.push(constant.STORECONFIGURATOR);
    this.user.appId = this.AppIDList;

    this.userRequest = {
      user: this.user,
      appId: this.AppIDList
    }

    this.userService.login(this.userRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedResponse = JSON.parse(response.data);
          let userDetails = receivedResponse.user;
          userDetails.pseudoName = receivedResponse.storeDetails.pseudoName;

          receivedResponse.user.pseudoName = userDetails.pseudoName;
          this.tokenStorageService.saveToken(receivedResponse.token);
          this.tokenStorageService.saveRefreshToken(receivedResponse.user.refreshToken);
          this.tokenStorageService.saveUser(receivedResponse.user);
          let auth0token: string = receivedResponse.authToken;
          this.tokenStorageService.saveAuth0Token(auth0token);

          if (userDetails.role == 'MMSAdministrator') {
            sessionStorage.setItem('roleType', 'MMSAdministrator');
            this.router.navigateByUrl('/organization');
          } else {
            sessionStorage.setItem('roleType', 'Administrator');
            this.router.navigateByUrl('/adminPage');
          }

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          if (response.data != undefined && response.data != null) {
            const errorMsg = JSON.parse(response.data);


            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, errorMsg.Message)
          } else {

            this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, response.message)
          }
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

  resetPassword() {
  }

  loginUsingAuth0() {
    this.authService.login();
  }
}
