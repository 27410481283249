import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';
import { StoreModalComponent } from '../store-modal/store-modal.component';
import { SessionService } from 'src/app/services/session.service';
import { StoreAdminModalComponent } from '../../storeAdmin/store-admin-modal/store-admin-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from 'src/app/services/store.service';
import { UtilityService } from 'src/app/services/utility.service';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Store } from 'src/app/models/Store';
import {
  MigrationStatus,
  ProcessorType,
  constant,
} from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { StoreRequest } from 'src/app/models/RequestModel/StoreRequest';
import { FormControl } from '@angular/forms';
import { PrimerxCloudPharmacyService } from 'src/app/services/primerx-cloud-pharmacy.service';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],

})


export class StoreComponent implements OnInit {
  Store: any = {};
  searchStore: any = {};
  currentPage = 0;
  pageSize = 10;
  totalRows = 0;
  storeArray: any = [];
  dbStoreArray: StoreTable[] = [];
  showNested: boolean = false;
  isSortEvent: boolean = false;

  displayedColumns = [
    'Detail',
    'OrganizationName',
    'NPINo',
    'pseudoName',
    'storeName',
    'subscription',
    'isPrimeRxCloudEmptyPharmacy',
    'contactName',
    'contactNo',

    'isStoreAdminCreated',
    'Action',
  ];

  innerDisplayedColumns = ['MigrationType', 'Status', 'Message', 'Option'];
  pageSizeOptions = [10, 50, 100];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<StoreTable>;
  // selection = new SelectionModel<Store>(true, []);
  expandedElement!: StoreTable | null;
  @ViewChildren('innerTables') innerTables!: QueryList<MatTable<StoreTable>>;
  storeRequest: StoreRequest = new StoreRequest();
  npiControl = new FormControl('');
  constructor(
    public modalService: NgbModal,
    private http: HttpClient,
    private router: Router,
    private userIdle: UserIdleService,
    private session: SessionService,
    private dialogService: MatDialog,
    private storeService: StoreService,
    private utilityService: UtilityService,
    private changeDetector: ChangeDetectorRef,
    private primerxCloudPharmacyService: PrimerxCloudPharmacyService,//EPPOS-3834
  ) { }

  ngOnInit(): void {
    this.SearchStoreData();
  }

  ngAfterViewInit() {
      this.sort.disableClear = true;
      this.sort.sortChange.subscribe(() => {
      this.isSortEvent=true;
      if(!this.isSortEvent)
      {
        this.paginator.pageIndex = 0;
      }

      const direction = this.sort.direction === 'asc' ? 'asc' : 'desc';
      const column = this.sort.active;
      this.storeRequest.sortColumn = column;
      this.storeRequest.sortDirection = direction;
    
      this.SearchStoreData();
    });
  }

  applyFilter(filterValue: any) {
    filterValue = filterValue; // Remove whitespace
    filterValue = filterValue.value.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  toggleRow(element: StoreTable) {
    element.migrationDetails &&
      (element.migrationDetails as MatTableDataSource<MigrationDetails>).data
        .length
      ? (this.expandedElement =
        this.expandedElement === element ? null : element)
      : null;
    this.changeDetector.detectChanges();
    // this.innerTables.forEach((table, index) => (table.dataSource as MatTableDataSource<MigrationDetails>).sort = this.innerSort.toArray()[index]);
  }

  //For NPI


  OpenStoreCreateModal(): void {
    const storeCreateDialogRef = this.dialogService.open(StoreModalComponent, {
      width: '60%',
      disableClose: true,
      data: {
        headerTitle: 'Create Pharmacy',
        isCreateMode: true,  // Pass flag for create mode
      },
    });

    storeCreateDialogRef.afterClosed().subscribe((result) => {
      //getting getStores on load for showing in the table after Creating the store
      this.SearchStoreData();
    });
  }

  OpenStoreUpdateModal(passStore: any) {
    const filteredStore = this.storeArray.find(
      (obj) => obj.NPINo == passStore.NPINo
    );

    const storeUpdateDialogRef = this.dialogService.open(StoreModalComponent, {
      width: '60%',
      disableClose: true,
      data: {
        userData: filteredStore,
        headerTitle: 'Update Pharmacy',
      },
    });

    storeUpdateDialogRef.afterClosed().subscribe((result) => {
      //getting getStores on load for showing in the table after Updating the store
      this.SearchStoreData();
    });
  }

  CreateAdmin(storeData: any): void {
    this.dialogService.open(StoreAdminModalComponent, {
      width: '50%',
      disableClose: true,

      data: {
        userData: storeData,
        headerTitle: 'Create Pharmacy Admin',
      },
    });
  }

  SearchStoreData(pageChanged: boolean = false) {
    if (!pageChanged && !this.isSortEvent) {
      this.currentPage = 0;
    }

    // setting pagination request object
    this.storeRequest.NPINo = this.searchStore.NPINo;
    this.storeRequest.pagination.pageNumber = this.currentPage + 1;
    this.storeRequest.pagination.pageSize = this.pageSize;
    this.storeRequest.isEdiv2 = this.searchStore.isEdiv2;
    this.storeRequest.isMpos = this.searchStore.isMpos;
    this.storeRequest.pseudoName = this.searchStore.pseudoName;
    this.storeRequest.isPrimeRxCloud = this.searchStore.isPrimeRxCloud;

    this.storeService.SearchStore(this.storeRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          let responseData = JSON.parse(response.data);

          this.storeArray = responseData.storeData;

          if (this.storeArray.length > 0) {
            this.mapModelForStoreTable();
            this.paginator!.pageIndex = this.currentPage;
            this.paginator!.length = responseData.totalRecords;
          } else {
            this.utilityService.showAlert(
              constant.KEY_ERROR,
              'Failed',
              'No Data Found'
            );
          }
        } else if (
          response.result == ResponseStatus.FAILURE ||
          response.result == ResponseStatus.ERROR
        ) {
          this.utilityService.showAlert(
            constant.KEY_ERROR,
            constant.FAILURE,
            response.message
          );
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(
          constant.FAILURE,
          constant.ALERT_FAILED_TITLE,
          errorResponse
        );
      },
    });
  }


  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    //if both the index are same then dont call the user api again for loading the data
    // if (event.pageIndex != event.previousPageIndex)
    this.SearchStoreData(true);
  }


  mapModelForStoreTable() {
    this.dbStoreArray = [];
    this.storeArray.forEach((store) => {
      if (
        store.primeRxCloudData?.migrationDetails &&
        store.primeRxCloudData.migrationDetails.length > 0
      ) {
        // If migrationDetails array exists and is not empty
        const migrationDetails: MigrationDetails[] =
          store.primeRxCloudData.migrationDetails.map((detail) => {
            return {
              migrationType: detail.migrationType,
              status: detail.status,
              message: detail.message,
            };
          });

        // Create MatTableDataSource if needed
        const dataSource = new MatTableDataSource<MigrationDetails>(
          migrationDetails
        );

        this.dbStoreArray.push({
          organizationName: store.organizationName,
          NPINo: store.NPINo,
          pseudoName: store.pseudoName,
          storeName: store.storeName,
          isEdiv2: store.isEdiv2,
          isMpos: store.isMpos,
          isPrimeRxCloud: store.isPrimeRxCloud,
          contactName: store.contactName,
          contactNo: store.contactNo,
          zipCode: store.zipCode,
          isStoreAdminCreated: store.isStoreAdminCreated,
          isPrimeRxCloudEmptyPharmacy: store.isPrimeRxCloudEmptyPharmacy,
          migrationDetails: dataSource,
          primeRxCloudData: store.primeRxCloudData
        });
      } else {
        // If primeRxCloudData or migrationDetails is missing or empty, just copy the store object
        this.dbStoreArray.push({
          organizationName: store.organizationName,
          NPINo: store.NPINo,
          pseudoName: store.pseudoName,
          storeName: store.storeName,
          isEdiv2: store.isEdiv2,
          isMpos: store.isMpos,
          isPrimeRxCloud: store.isPrimeRxCloud,
          contactName: store.contactName,
          contactNo: store.contactNo,
          zipCode: store.zipCode,
          isStoreAdminCreated: store.isStoreAdminCreated,
          isPrimeRxCloudEmptyPharmacy: store.isPrimeRxCloudEmptyPharmacy,
          primeRxCloudData: store.primeRxCloudData
        });
      }
    });

    this.dataSource = new MatTableDataSource(this.dbStoreArray);
    this.dataSource.sort = null;
  }

  getTooltipText(isMpos: boolean, isEdiv2: boolean): string {
    return isMpos || isEdiv2 ?
      "Create Admin For EDI Cloud and PrimeRx POS Pharmacy" :
      "This is not valid for PrimeRx Cloud";
  }

  keyPressNumeric(event: any) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  //EPPOS-3834
  RestartMigration(pharmacy: any, migrationdata: any) {


    let restartMigrationRequest = {
      NPINo: pharmacy.NPINo,
      MMSDbName: pharmacy.primeRxCloudData.masterDbName,
      MigrationType: migrationdata.migrationType
    }

    this.primerxCloudPharmacyService.RestartMigration(restartMigrationRequest).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          this.utilityService.showAlert(constant.KEY_SUCCESS, constant.ALERT_SUCCESS_TITLE, response.message);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.ALERT_FAILED_TITLE, response.message);
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });

  }

}
interface MigrationDetails {
  status: MigrationStatus;
  message: string;
  migrationType: string;
}



export interface StoreTable {
  organizationName: string;
  NPINo: string;
  pseudoName: string;
  storeName: string;
  isEdiv2: boolean;
  contactName: string;
  contactNo: string;
  zipCode: string;
  isStoreAdminCreated: boolean;
  isPrimeRxCloud: boolean;
  isMpos: boolean;
  isPrimeRxCloudEmptyPharmacy: boolean;
  primeRxCloudData: any
  migrationDetails?: MigrationDetails[] | MatTableDataSource<MigrationDetails>;
}
interface MigrationDetails {
  status: MigrationStatus;
  message: string;
  migrationType: string;
}
