import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreConfiguratorRoutingModule } from './store-configurator-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../materialComponents/material.module';
import { PayTypeModal, StoreModalComponent } from './store/store-modal/store-modal.component';
import { StoreComponent } from './store/store/store.component';
import { StoreAdminModalComponent } from './storeAdmin/store-admin-modal/store-admin-modal.component';
import { StoreAdminComponent } from './storeAdmin/store-admin/store-admin.component';
import { DataTablesModule } from "angular-datatables";
import { HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { MatTableExporterModule } from 'mat-table-exporter';
import { AdminPageComponent } from './AdminPage/admin-page/admin-page.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationModalComponent } from './organization/organization-modal/organization-modal.component';
import { ReusableComponentsModule } from '../ReusableComponents/reusable-components.module';
import { ContractReplaceOrNoComponent } from '../utility/contractReplaceAlert/contract-replace-or-no.component';
import { TrimSpaceInputDirective } from '../directives/trim-space-input.directive';
import { LowercaseDirective } from '../directives/lowercase.directive';
import { OTCBenifitsComponent } from './otcbenifits/otcbenifits.component';
import { OTCBenifitsModalComponent } from './otcbenifits/otcbenifitsModal/otcbenifits-modal.component';
import { MessageServiceConfigurationComponent } from './message-service-configuration/message-service-configuration.component';
import { ServiceProviderModalComponent } from './message-service-configuration/service-provider-modal/service-provider-modal.component';
import { MMSAdminComponent } from './mmsAdmin/mms-admin/mms-admin.component';
import { MMSAdminModalComponent } from './mmsAdmin/mms-admin-modal/mms-admin-modal.component';
import { StoreAdminPasswordResetModalComponent } from './storeAdmin/store-admin-password-reset-modal/store-admin-password-reset-modal.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    StoreComponent,
    StoreModalComponent,
    StoreAdminModalComponent,
    StoreAdminComponent,
    AdminPageComponent,
    OrganizationComponent,
    OrganizationModalComponent,
    ContractReplaceOrNoComponent,
    PayTypeModal,
    TrimSpaceInputDirective,
    LowercaseDirective,
    OTCBenifitsComponent,
    OTCBenifitsModalComponent,
    MessageServiceConfigurationComponent,
    ServiceProviderModalComponent,
    MMSAdminComponent,
    MMSAdminModalComponent,
    StoreAdminPasswordResetModalComponent
  ],
  imports: [
    CommonModule,
    StoreConfiguratorRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgbModule,
    DataTablesModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    MatTableModule,
    MatSelectModule,
    MatDialogModule,
    DataTablesModule,
    MatTableExporterModule,
    NgxMaskModule.forRoot(maskConfig),
    MatFormFieldModule,
    MatInputModule,
    MatPasswordStrengthModule,
    ReusableComponentsModule,
  ]
})
export class StoreConfiguratorModule { }
