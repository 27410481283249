import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, FormBuilder, Validators } from '@angular/forms';
import { timeStamp } from 'console';
import { startWith, map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { stateConstant } from 'src/app/constant/stateConstant';
import { State } from 'src/app/models/State';
import { Store } from 'src/app/models/Store';
import { EdiUserAccess } from 'src/app/models/EdiUserAccess';
import { Vendor } from 'src/app/models/Vendor';
import { EDIStoreService } from 'src/app/services/edi_store.service';
import { EdiUserService } from 'src/app/services/edi_user.service';
import { EdiUtilityService } from 'src/app/services/edi-utility.service';
import { VendorService } from 'src/app/services/Vendor/vendor.service';
import { ediconstant } from 'src/app/constant/ediconstant';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { MatSelectModule } from '@angular/material/select';
import { constants } from 'buffer';
import { constant } from 'src/app/constant/constant';
import { ResponseStatus } from 'src/app/models/ApiResponse';
import { StateMasterService } from 'src/app/services/StateMaster/statemaster.service';

export interface test {
  name: string;
  post: string;
}


@Component({
  selector: 'app-vendor-information',
  templateUrl: './vendor-information.component.html',
  styleUrls: ['./vendor-information.component.css'],

})


export class VendorInformationComponent implements OnInit, OnChanges {
  vendorForm: FormGroup; //EPPOS-3755
  user: any = {};
  vendorInformation: Vendor = {
    mId: '',
    NPINo: '',
    vendorId: '',
    vendorCode: '',
    vendorName: '',
    vendorAccount:'',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phoneOff: '',
    cellNo: '',
    faxNo: '',
    webAddress: '',
    emailAddress: '',
    isActive: false,
    POExpiresInDays: '',
    overdueTime: '',
    process810: false,
    isDelete: false,
    pseudoName: '',
    priceQualifier: '',
    costQualifier: '',
    saleQualifier: '',
    isEPO: false,
    salePriceUpdate: false,
    vendorCostPrice: false,
    autoClose: false,
    updatePrice832: false,
    ackPriceUpdate: false,
    reduceSellingPriceWith832File: false,
    processSwitch810: '',
    processSwitch832: '',
    processSwitch835: '',
    processSwitch855: '',
    updateAWPThroughEDI: '0',
    updateCostThroughEDI: '0',
    firstTimeInventory: '0'

  };

  updateAWPThroughEDI: boolean = true;
  updateCostThroughEDI: boolean = true;
  firstTimeInventory: boolean = true;

  stateArray: State[] = stateConstant.states;
  priceQualifier: string[] = ediconstant.qualifiers;
  costQualifier: string[] = ediconstant.qualifiers;
  saleQualifier: string[] = ediconstant.qualifiers;
  fileOptions: string[] = ediconstant.fileOptions;
  storeArray!: Store[];
  vendorMasterArray: Vendor[] = [];
  isMMSAdmin: boolean = false;
  existingMappedVendor: Vendor[] = [];
  unMappedVendor: Vendor[] = [];
  userAccessData!: EdiUserAccess;
  selectedNPINo: string = "";
  selectedVendorCode: string = "";
  @ViewChild('vendorInformationForm') public vendorInfoForm!: NgForm;
  @Input() disableFields: boolean = false;
  @Input() receivedVendorInfo!: Vendor;
  @Input() isFromMapping: boolean = false;//this is to check from where the component is called and on this added show hide condition
  @Input() isUpdate: boolean = false; //for knowing update or create and added condition for show hide
  @Input() isCreate: boolean = false; //for knowing update or create and added condition for show hide

  @Output("getFullMasterVendor") passVendorCodeToMappingDialog: EventEmitter<any> = new EventEmitter();

  //variable for searchable dropdown
  npiControl = new FormControl('');
  filteredOptions: Observable<Store[]> | undefined;

  //vendorNameControl = new FormControl('');
  vendorNameControl = new FormControl('', Validators.required);
  filteredVendorOptions: Observable<Vendor[]> | undefined;

  constructor(
    private fb: FormBuilder,//EPPOS-3755
    private storeService: EDIStoreService,
    private vendorService: VendorService,
    private userService: EdiUserService,
    private utilityService: EdiUtilityService,
    private tokenStorageService: TokenStorageService,
    private stateMasterService: StateMasterService //EPPOS-3755
  ) {
    //this.userAccessData = this.userService.getEdiUsersAccessData();

    //EPPOS-3755
    this.vendorForm = this.fb.group({
      city: [''],
      state: [''],
      zip: ['']
    });
    //EPPOS-3755

    const user = this.tokenStorageService.getUser();

    if (!this.userAccessData) {
      this.userAccessData = {} as any;
    }

    this.userAccessData.NPINo = user.NPINo;

    this.vendorInformation.processSwitch810 = "DownloadAndProcess";
    this.vendorInformation.processSwitch832 = "DownloadAndProcess";
    this.vendorInformation.processSwitch835 = "DownloadAndProcess";
    this.vendorInformation.processSwitch855 = "DownloadAndProcess";
    // this.vendorInformation.firstTimeInventory = "1";
    // this.vendorInformation.updateAWPThroughEDI = "1";
    // this.vendorInformation.updateCostThroughEDI = "1";


    if (this.userAccessData.NPINo != "" && this.userAccessData.NPINo == "0000000000") {
      this.isMMSAdmin = true;
      this.loadAllNPINo();
    }
    this.loadMasterVendors();

  }


  ngOnInit(): void {
    
    if (this.receivedVendorInfo != undefined) {
      this.vendorInformation = this.receivedVendorInfo;
      //EDI Settings
      this.vendorInformation.firstTimeInventory = this.vendorInformation.firstTimeInventory ?? "0";
      this.vendorInformation.updateAWPThroughEDI = this.vendorInformation.updateAWPThroughEDI ?? "0";
      this.vendorInformation.updateCostThroughEDI = this.vendorInformation.updateCostThroughEDI ?? "0";

      this.firstTimeInventory = this.vendorInformation.firstTimeInventory === "1";
      this.updateAWPThroughEDI =  this.vendorInformation.updateAWPThroughEDI === "1";
      this.updateCostThroughEDI = this.vendorInformation.updateCostThroughEDI === "1";
    }
    else
    {
      this.vendorInformation.firstTimeInventory = "0";
      this.vendorInformation.updateAWPThroughEDI = "0";
      this.vendorInformation.updateCostThroughEDI = "0";
      this.firstTimeInventory = false;
      this.updateAWPThroughEDI =  false;
      this.updateCostThroughEDI = false;

    }

  }

  ngOnChanges(changes: SimpleChanges) {
    //THIS DATA IS BEING FORWARED FROM PARENT COMPONENT AND THIS METHOD CHECKS IF THE DATA IS CHANGED AND THEN IT ASSIGNES THE DATA
    if (changes['receivedVendorInfo'] != undefined) {
      if (changes['receivedVendorInfo'].currentValue != undefined) {
        this.vendorInformation = changes['receivedVendorInfo'].currentValue;
        if (this.selectedNPINo)
          this.vendorInformation.NPINo = this.selectedNPINo;
      }

    }

    if (changes['isFromMapping'] != undefined) {
      //FOR SHOWING THE NPI FILTER TO MMS ADMIN
      if (changes['isFromMapping'].currentValue != undefined)
        this.isFromMapping = changes['isFromMapping'].currentValue;

    }

  }

  onCheckboxChange(variable: string, value: boolean) {
    this[variable] = value;
    this.vendorInformation[variable] = value ? "1" : "0"; // Convert boolean to string "1" or "0"
  }

  //#region //EPPOS-3755
  fetchStateAndCity(): void {
    const zip = this.vendorInformation.zip;
    if (zip && zip.length != 1) { // Assuming ZIP should be exactly 5 characters long
      this.stateMasterService.searchStateCityByZip(zip).subscribe({
        next: (response) => {
          if (response.result === ResponseStatus.SUCCESS) {
            const data = response.data;
            const state = this.stateArray.find(s => s.abbreviation === data.state.name); // Ensure the key is correct
            if (state) {
              this.vendorInformation.state = state.abbreviation; // Set abbreviation here
            }
            this.vendorInformation.city = data.city.name || ''; // Update city
          } else {
            this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, 'Failed to fetch state and city data');
          }
        },
        error: (error) => {
          console.error('Error fetching state and city:', error);
          this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, 'Failed to fetch state and city data');
        }
      });
    }
  }
  //#endregion

  setValueToNPIDropdown() {

    this.filteredOptions = this.npiControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const npi = typeof value === 'string' ? value : value?.npi;
        var retFilter = npi ? this.filterNPINos(npi as string) : this.storeArray.slice();
        return retFilter;
      }),
    );
  }

  setNPINoToModel(event: any) {
    this.vendorInformation.NPINo = event.option.value.NPINo;

    this.selectedNPINo = event.option.value.NPINo;//adding the value of selected npi to the field

    //THIS WILL GET THE VENDORS AND THEN DIFFERENCE OF MAPPED VENDORS
    this.getUnMappedVendors(this.vendorInformation.NPINo);

  }

  private filterNPINos(name: string): Store[] {
    const filterValue = name.toLowerCase();

    var retNPI = this.storeArray.filter(option => option.NPINo.toLowerCase().includes(filterValue));
    return retNPI;

  }

  displayNPINos(storeData: Store): string {
    return storeData && storeData.NPINo ? storeData.NPINo : '';
  }

  returnVendorInfoData() {
    let retVendorData = {
      vendorInformation: this.vendorInformation,
      vendorInfoForm: this.vendorInfoForm
    }
    return retVendorData;
  }

  //=======will fetch all the NPINo presenet in the store =============
  loadAllNPINo() {

    this.storeService.getAllNPINo().subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.storeArray = receivedVendorResponse;
          this.setValueToNPIDropdown();

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }


  //=======will fetch all the master vendor and populate the vendor dropdown =============
  loadMasterVendors() {

    let vendorData = {};
    this.vendorService.getMasterVendor(vendorData).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {
          var receivedVendorResponse = JSON.parse(response.data);
          this.vendorMasterArray = receivedVendorResponse;
          this.vendorMasterArray = this.vendorMasterArray.filter(vendor => vendor.isActive);
          this.getUnMappedVendors(this.userAccessData.NPINo);

        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching list of NPINo");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }


  //#region master vendor dropdown
  //this methods are user for filtering the data from the vendor master array
  setValueToVendorDropdown() {

    this.filteredVendorOptions = this.vendorNameControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const vendorCode = typeof value === 'string' ? value : value?.vendorCode;
        var retFilterVendor = vendorCode ? this.filterVendorNames(vendorCode as string) : this.vendorMasterArray.slice();
        return retFilterVendor;
      }),
    );
  }

  validateVendor(){
    const enteredValue = this.    vendorNameControl?.value?.trim();
    const isValid = this.vendorMasterArray.some(option => option.vendorName === enteredValue)
        if(!isValid){
          console.log("error in vendor name")
          this.vendorNameControl?.setErrors({invalid : true});
          return;
        }else {
          this.vendorNameControl?.setErrors(null);
          return;
        }
  }

  validateNPINo() {
  const enteredValue = this.npiControl?.value?.trim(); // Get entered value

      if (!enteredValue ||  enteredValue.length !== 10) {
        this.npiControl?.setErrors({ invalidLength: true }); // Show error if not exactly 10 digits
        return;
      }

      const isValid = this.storeArray.some(option => option.NPINo === enteredValue);

      if (!isValid) {
      this.npiControl?.setErrors({ invalidNPI: true }); // Show error if NPI doesn't exist
        console.log('Invalid NPI - Setting error'); 
        return;
      } else {
        this.npiControl?.setErrors(null); // Clear error if valid
        return;
      }  
}

  

  setVendorNameToModel(event: any) {
    // this.vendorInformation.vendorCode = event.option.value.vendorCode;
    //take out the vendor field and vendor connection data

    this.selectedVendorCode = event.option.value.vendorCode;//adding the value of selected vendor code to the field
    this.firstTimeInventory = event.option.value.firstTimeInventory === "1";
    this.updateAWPThroughEDI =  event.option.value.updateAWPThroughEDI === "1";
    this.updateCostThroughEDI = event.option.value.updateCostThroughEDI === "1";
    //calling the vendor map dialog method for sending vendor code data
    this.passVendorCodeToMappingDialog.emit(event.option.value.vendorCode);

  }

  private filterVendorNames(name: string): Vendor[] {
    const filterValue = name.toLowerCase();

    var retFilteredVendor = this.vendorMasterArray.filter(option => option.vendorCode.toLowerCase().includes(filterValue));
    return retFilteredVendor;

  }

  displayVendorName(vendorData: Vendor): string {
    return vendorData && vendorData.vendorName ? vendorData.vendorName : '';
  }
  

  //#endregion Region Name Here

  getUnMappedVendors(receivedNPINo: string) {

    let passVendorInfo = {
      NPINo: receivedNPINo
    };

    this.vendorService.getVendorData(passVendorInfo).subscribe({
      next: (response) => {
        if (response.result == ResponseStatus.SUCCESS) {

          var receivedVendorResponse = JSON.parse(response.data);
          this.existingMappedVendor = receivedVendorResponse;

          //now getting the diffrence between the master vendor and mapped  vendor
          this.vendorMasterArray = this.vendorMasterArray.filter(unmappedVendor =>
            !this.existingMappedVendor.some(mappedVendor => (unmappedVendor.vendorCode == mappedVendor.vendorCode)));

          //AFTER GETTING THE DIFFERENCE AGAIN CALLING THE SET METHOD FOR ATTACHING THE FILTERED VENDORS
          this.setValueToVendorDropdown();
        } else if ((response.result == ResponseStatus.FAILURE) || (response.result == ResponseStatus.ERROR)) {

          this.utilityService.showAlert(constant.KEY_ERROR, constant.FAILURE, "Error while fetching mapped vendors data");
        }
      },
      error: (errorResponse) => {
        console.log(errorResponse);
        this.utilityService.showAlert(constant.FAILURE, constant.ALERT_FAILED_TITLE, errorResponse);
      }
    });
  }

  AllowOnlyNumber(event: any) {

    return this.utilityService.AllowOnlyNumber(event);

  }

  AllowOnlyCharacter(event: any) {

    return this.utilityService.AllowOnlyCharacter(event);

  }

}
