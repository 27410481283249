import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminPageGaurd } from '../gaurds/adminPage.guard';
import { HealthMonitorGaurd } from '../gaurds/healthMonitor.guard';
import { StoreGuard } from '../gaurds/store.guard';
import { StoreAdminGaurd } from '../gaurds/storeAdmin.guard';
import { AdminPageComponent } from './AdminPage/admin-page/admin-page.component';
import { HealthMonitorComponent } from './healthMenu/healthMonitor/health-monitor/health-monitor.component';
import { StoreComponent } from './store/store/store.component';
import { StoreAdminComponent } from './storeAdmin/store-admin/store-admin.component';
import { OrganizationComponent } from './organization/organization.component';
import { OTCBenifitsComponent } from './otcbenifits/otcbenifits.component';
import { MessageServiceConfigurationComponent } from './message-service-configuration/message-service-configuration.component';
import { OtcBenifitsGuard } from '../gaurds/otc-benifits.guard';
import { MessageServiceGuard } from '../gaurds/message-service.guard';
import { AuthGuard } from '../gaurds/auth.guard';
import { MMSAdminComponent } from './mmsAdmin/mms-admin/mms-admin.component';

const routes: Routes = [
  { path: '', component: OrganizationComponent},
  { path: 'organization', component: OrganizationComponent,canActivate: [AuthGuard]},
  { path: 'store', component: StoreComponent,canActivate: [AuthGuard]},
  { path: 'storeAdmin', component: StoreAdminComponent,canActivate: [AuthGuard]},
  { path: 'healthMonitor', component: HealthMonitorComponent,canActivate: [AuthGuard]},
  { path: 'adminPage', component: AdminPageComponent,canActivate: [AuthGuard]},
  { path: 'otcBenefits', component:OTCBenifitsComponent,canActivate:[AuthGuard]},
  { path: 'serviceProvider', component:MessageServiceConfigurationComponent,canActivate:[AuthGuard]},
  { path: 'userManagement', component:MMSAdminComponent,canActivate:[AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoreConfiguratorRoutingModule { }
