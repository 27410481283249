export const environment = {
  production: false,

  domain: 'devprimerx.us.auth0.com',
  clientId: 'rPKGsF8oOth2S5Qk98xezkHyKRKhylck',
  audience:'https://api.dev.eprimerx.com',

  baseURL: "https://api.dev.pos.eprimerx.com",
  basePrimeComTokenURL: "https://devprimerx.us.auth0.com",
  basePrimeComURL: "https://primecomm.dev.eprimerx.com",
  recaptcha: {
    siteKey: '6LdfkqwZAAAAAMAjYgEirtFdoSDM007DtbMzNhwF'
  }
};