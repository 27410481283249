import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { BaseHttpService } from '../utility/BaseHttpService'; // Import BaseHttpService
import { constant } from '../constant/constant';
import { catchError, mergeMap, of, retryWhen, throwError, timer } from 'rxjs'; // Import `of` for handling null or empty observables
import { ErrorHandlerService } from "./error-handler.service";

@Injectable({
    providedIn: 'root'
  })

  export class OnBoardingService {
    constructor(private baseHttpService: BaseHttpService, private httpClient: HttpClient, private errorHandlerService: ErrorHandlerService) { }


    UpdateEDISettings (request: any){
        return this.baseHttpService.post<any>((constant.ONBOARDING_SERVICE_URL + constant.UPDATE_EDI_SETTIGNS), request, constant.httpClientHeader.headers);
        //return this.baseHttpService.post<any>(("https://localhost:7156/OnBoarding/UpdateEDISettings"), request, constant.httpClientHeader.headers);
    }
  }